import React, { FC, useEffect, useMemo, useState } from 'react'
import { Menu, MenuProps } from 'antd'
import styles from './Header.module.scss'
import {
    useLocalizationContext,
    useMobile,
    useUnsignedDocumentsContext,
} from '../../utils/contexts'
import {
    BarChartOutlined,
    CreditCardOutlined,
    FileAddOutlined,
    InfoCircleOutlined,
    LogoutOutlined,
    MailOutlined,
    MenuOutlined,
    NotificationOutlined,
    OrderedListOutlined,
    SnippetsOutlined,
    UsergroupAddOutlined,
    UserOutlined,
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { HomeActions, HomeSelectors } from '../../redux/reducers/home'
import { Links } from '../../constants/routes'
import { useLocation, useNavigate } from 'react-router-dom'
import { EnIcon, RuIcon } from '../../images'
import { Language } from '../../@types/common'
import { env } from '../../env'
import { OFFER_ASK_CREATE, OFFER_BID_CREATE } from '../../@types/accesses'
import { OrdersSelectors } from '../../redux/reducers/orders'
import classNames from 'classnames'
import { DocumentsSelectors } from '../../redux/reducers/documents'

type MenuItem = Required<MenuProps>['items'][number]

const SHOW_LANGUAGE_SWITCH = false

export const Header: FC = () => {
    const { localize, formatLocalize } = useLocalizationContext()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const isMobile = useMobile()
    const [isMenuVisible, setIsMenuVisible] = useState(false)
    const isLoggedIn = useSelector(HomeSelectors.getIsLoggedIn)
    const user = useSelector(HomeSelectors.getUser)
    const locale = useSelector(HomeSelectors.getLocale)
    const orders = useSelector(OrdersSelectors.getOrders)
    const accesses = useSelector(HomeSelectors.getAccesses)
    const documents = useSelector(DocumentsSelectors.getDocuments)
    const { setVisible } = useUnsignedDocumentsContext()

    useEffect(() => {
        setIsMenuVisible(false)
    }, [isMobile])

    const leftMenuItems: MenuItem[] = useMemo(() => {
        return [
            ...(SHOW_LANGUAGE_SWITCH
                ? [
                      {
                          icon:
                              locale === Language.English ? (
                                  <EnIcon />
                              ) : (
                                  <RuIcon />
                              ),
                          label: `${localize('language')}: ${locale}`,
                          key: 'locale',
                      },
                  ]
                : []),
            {
                icon: <NotificationOutlined />,
                label: formatLocalize('ordersCount', {
                    count: orders?.total.toString() || '0',
                }),
                key: Links.Orders,
            },
            {
                icon: <SnippetsOutlined />,
                label: localize('myOffers'),
                key: Links.MyOffers,
            },
            {
                icon: <CreditCardOutlined />,
                label: localize('cards'),
                key: Links.Cards,
            },
            {
                icon: <InfoCircleOutlined />,
                label: localize('verification'),
                key: Links.Wizard,
            },
            {
                icon: <UsergroupAddOutlined />,
                label: localize('referralTitle'),
                key: Links.Referral,
            },
            {
                icon: <MailOutlined />,
                label: localize('support'),
                key: 'support',
            },
            {
                icon: <InfoCircleOutlined />,
                label: localize('returnGuarantee'),
                key: 'guarantee',
            },
            {
                icon: <LogoutOutlined />,
                label: localize('logout'),
                key: Links.Logout,
            },
        ]
    }, [locale, orders, isMobile, documents])

    const profileSubMenu: MenuItem[] = useMemo(
        () => [
            {
                icon: <SnippetsOutlined />,
                label: 'Профиль',
                key: Links.Profile,
            },
            {
                icon: <SnippetsOutlined />,
                label: 'Данные из МСИ',
                key: Links.MsiData,
            },
            {
                icon: <SnippetsOutlined />,
                label: 'Анкета',
                key: Links.Questionnaire,
            },
            ...(documents && documents.length > 0
                ? [
                      {
                          icon: <SnippetsOutlined />,
                          label: 'На подпись: ' + documents.length + ' док.',
                          key: 'documentsModal',
                      },
                  ]
                : []),
        ],
        [user?.login, isMobile, leftMenuItems, documents]
    )

    const profileMenuItem: MenuItem = useMemo(
        () => ({
            icon: <UserOutlined />,
            label: user?.login,
            key: 'profile',
            theme: 'dark',
            children: isMobile
                ? [...profileSubMenu, ...leftMenuItems]
                : profileSubMenu,
        }),
        [user?.login, isMobile, leftMenuItems]
    )

    const items: MenuItem[] = useMemo(() => {
        const makeOfferChildren = [
            ...(accesses && accesses[OFFER_ASK_CREATE]
                ? [{ label: localize('ask'), key: Links.NewAskOffer }]
                : []),
            ...(accesses && accesses[OFFER_BID_CREATE]
                ? [{ label: localize('bid'), key: Links.NewBidOffer }]
                : []),
        ]

        return [
            {
                icon: <OrderedListOutlined />,
                label: localize('offers'),
                key: Links.Offers,
            },
            {
                icon: <BarChartOutlined />,
                label: localize('leaderboard'),
                key: Links.Leaderboard,
            },
            ...(makeOfferChildren.length > 0
                ? [
                      {
                          icon: <FileAddOutlined />,
                          label: localize('makeNewOffer'),
                          key: 'SubMenu',
                          children: makeOfferChildren,
                      },
                  ]
                : []),
            ...(isMobile ? [profileMenuItem] : []),
        ]
    }, [accesses, locale, orders, isMobile, profileMenuItem])

    const onClick: MenuProps['onClick'] = (e) => {
        switch (e.key) {
            case 'locale':
                dispatch(
                    HomeActions.setLocale(
                        locale === Language.English
                            ? Language.Russian
                            : Language.English
                    )
                )
                break
            case 'support':
                window.open(`mailto:${env.REACT_APP_SUPPORT_EMAIL}`)
                break
            case 'guarantee':
                window.open(
                    env.REACT_APP_RETURN_GUARANTEE,
                    '_blank',
                    'noopener,noreferrer'
                )
                break
            case 'documentsModal':
                setVisible(true)
                break
            default:
                navigate(e.key)
                break
        }
    }

    const selectedKeys = useMemo(
        () =>
            !![Links.ViewOffer, Links.EditBidOffer, Links.EditAskOffer]
                .map((x) => x.replace(':id', ''))
                .find((x) => location.pathname.startsWith(x))
                ? [Links.Offers]
                : !![Links.ViewOrder]
                        .map((x) => x.replace(':id', ''))
                        .find((x) => location.pathname.startsWith(x))
                  ? [Links.Orders]
                  : [location.pathname],
        [location.pathname]
    )

    const onClickMobile: MenuProps['onClick'] = (e) => {
        setIsMenuVisible(false)
        onClick(e)
    }

    return (
        <div
            className={classNames(styles.container, {
                [styles.containerMobile]: isMobile,
            })}
        >
            <span
                className={styles.logo}
                onClick={() => navigate(Links.Offers)}
            >
                Logo
            </span>
            {isLoggedIn ? (
                isMobile ? (
                    <div className={styles.hamburgerMenu}>
                        <MenuOutlined
                            onClick={() => setIsMenuVisible(!isMenuVisible)}
                            className={styles.menuIcon}
                        />
                        {isMenuVisible && (
                            <div className={styles.menuContent}>
                                <Menu
                                    onClick={onClickMobile}
                                    selectedKeys={selectedKeys}
                                    mode={'inline'}
                                    items={items}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <Menu
                        className={styles.menu}
                        onClick={onClick}
                        selectedKeys={selectedKeys}
                        mode={'horizontal'}
                        items={items}
                    />
                )
            ) : null}
            {!isMobile && isLoggedIn && (
                <Menu
                    className={styles.leftMenu}
                    onClick={onClick}
                    selectedKeys={selectedKeys}
                    defaultOpenKeys={['profile']}
                    mode={'inline'}
                    theme={'dark'}
                    forceSubMenuRender
                    items={[profileMenuItem, ...leftMenuItems]}
                />
            )}
        </div>
    )
}
