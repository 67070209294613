import { all, call, put, StrictEffect, takeLeading } from 'redux-saga/effects'
import {
    SagaGenerator,
    WithCallback,
    WithCode,
    WithLocalize,
    WithObjectId,
} from '../../../@types/common'
import { Api } from '../index'
import { ApiResponse } from 'apisauce'
import { DocumentsActions } from '../../reducers/documents'
import { Document, SpecificDocument } from '../../reducers/documents/types'
import { ActionCreatorWithPayload, PayloadAction } from '@reduxjs/toolkit'
import { throwNotification } from '../../../components/Notification'
import { NoticeTypes } from '../../../@types/notifications'

export function* requestDocuments(api: Api) {
    yield put(DocumentsActions.setDocumentsLoading(true))

    let result: ApiResponse<Document[]> = yield call(api.listDocuments)

    if (result.status === 200 && result.data) {
        yield put(DocumentsActions.setDocuments(result.data))
    }

    yield put(DocumentsActions.setDocumentsLoading(false))
}

export function* requestSpecificDocument(
    api: Api,
    action: PayloadAction<WithObjectId<{}>>
) {
    const { id } = action.payload

    yield put(DocumentsActions.setSpecificDocumentLoading(true))

    let result: ApiResponse<SpecificDocument> = yield call(
        api.getSpecificDocument,
        id
    )

    if (result.status === 200 && result.data) {
        yield put(DocumentsActions.setSpecificDocument(result.data))
    }

    yield put(DocumentsActions.setSpecificDocumentLoading(false))
}

export function* requestSendSms(
    api: Api,
    action: PayloadAction<WithLocalize<WithObjectId<{}>>>
) {
    const { id, localize } = action.payload

    let result: ApiResponse<any> = yield call(api.sendSms, id)

    if (result.data.phone) {
        yield put(DocumentsActions.setSentOnPhone(result.data.phone))
    }

    if (result.data.sent) {
        throwNotification(NoticeTypes.Success, localize('smsHaveSend'))
    } else {
        if (result.data.message) {
            throwNotification(NoticeTypes.Error, result.data.message)
        }
    }
}

export function* signWithSms(
    api: Api,
    action: PayloadAction<
        WithCode<WithCallback<WithLocalize<WithObjectId<{}>>>>
    >
) {
    const { localize, callback, id, code } = action.payload

    let result: ApiResponse<any> = yield call(api.signWithSms, id, code)

    if (result.status === 200) {
        throwNotification(NoticeTypes.Success, localize('signedNotification'))
        callback?.()
    }
}

export function* declineDocument(
    api: Api,
    action: PayloadAction<WithObjectId<WithCallback<WithLocalize<{}>>>>
) {
    const { localize, callback, id } = action.payload

    let result: ApiResponse<any> = yield call(api.cancelSpecificDocument, id)

    if (result.status === 200) {
        throwNotification(NoticeTypes.Success, localize('canceledDocument'))
        callback?.()
    }
}

export default function* groupsSagas(api: Api): SagaGenerator<StrictEffect> {
    yield all([
        yield takeLeading(
            DocumentsActions.requestDocuments,
            requestDocuments,
            api
        ),
        yield takeLeading(
            DocumentsActions.requestSpecificDocument as ActionCreatorWithPayload<
                WithObjectId<{}>
            >,
            requestSpecificDocument,
            api
        ),
        yield takeLeading(
            DocumentsActions.requestSendSms as ActionCreatorWithPayload<
                WithLocalize<WithObjectId<{}>>
            >,
            requestSendSms,
            api
        ),
        yield takeLeading(
            DocumentsActions.declineDocument as ActionCreatorWithPayload<
                WithObjectId<WithCallback<WithLocalize<{}>>>
            >,
            declineDocument,
            api
        ),
        yield takeLeading(
            DocumentsActions.signWithSms as ActionCreatorWithPayload<
                WithCode<WithCallback<WithLocalize<WithObjectId<{}>>>>
            >,
            signWithSms,
            api
        ),
    ])
}
