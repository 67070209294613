import { FC, useEffect } from 'react'
import classNames from 'classnames'
import styles from './ProfilePage.module.scss'
import { PageTitle } from '../../components'
import { FileOutlined } from '@ant-design/icons'
import { useLocalizationContext, useMobile } from '../../utils/contexts'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Space, Typography } from 'antd'
import { HomeActions, HomeSelectors } from '../../redux/reducers/home'
import { DATE_TIME_FORMAT } from '../../constants'
const { Text } = Typography

export const ProfilePage: FC = () => {
    const dispatch = useDispatch()
    const isMobile = useMobile()
    const { localize } = useLocalizationContext()
    const userScore = useSelector(HomeSelectors.getUserScore)

    useEffect(() => {
        dispatch(HomeActions.requestScore())
    }, [dispatch])

    return (
        <div
            className={classNames(styles.pageContainer, {
                [styles.pageContainerMobile]: isMobile,
            })}
        >
            <PageTitle title={'Профиль'} icon={<FileOutlined />} />
            <Space direction={'horizontal'} style={{ width: '100%' }}>
                {userScore?.value !== undefined ? (
                    <>
                        <Space direction={'vertical'} style={{ width: '100%' }}>
                            <Text>{localize('score')}</Text>
                            <Input
                                disabled
                                value={userScore.value}
                                style={{ width: '100%' }}
                            />
                        </Space>
                        <Space direction={'vertical'} style={{ width: '100%' }}>
                            <Text>{localize('date')}</Text>
                            <Input
                                disabled
                                value={userScore.date?.format(DATE_TIME_FORMAT)}
                                style={{ width: '100%' }}
                            />
                        </Space>
                    </>
                ) : (
                    <Space direction={'vertical'} style={{ width: '100%' }}>
                        <Text>{localize('scoreUndefined')}</Text>
                    </Space>
                )}
            </Space>
        </div>
    )
}
