import React, {
    FC,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from 'react'
import { useLocalizationContext, useMobile } from '../../utils/contexts'
import classNames from 'classnames'
import styles from './ViewOrderPage.module.scss'
import { PageTitle } from '../../components'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Links } from '../../constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { Space, Tag, Typography } from 'antd'
import { User } from '../../redux/reducers/home/types'
import { OrdersActions, OrdersSelectors } from '../../redux/reducers/orders'
import { Moment } from 'moment'
import { DATE_TIME_FORMAT } from '../../constants'
import { Order, OrderState } from '../../redux/reducers/orders/types'
import {
    orderStateMapping,
    useOrdersColumns,
} from '../OrdersPage/OrdersColumns'
import { Offer, OfferType } from '../../redux/reducers/offers/types'
import {
    DocumentsActions,
    DocumentsSelectors,
} from '../../redux/reducers/documents'

type Mappings = {
    key: keyof Order
    text: string
    mapping: (value: any) => React.ReactElement | string
}[]

export const ViewOrderPage: FC = () => {
    const dispatch = useDispatch()
    const { formatLocalize, localize, pluralize } = useLocalizationContext()
    const isMobile = useMobile()
    const navigate = useNavigate()
    const params = useParams()
    const ref = useRef<HTMLDivElement>(null)

    const specificOffer = useSelector(OrdersSelectors.getSpecificOrder)
    const documents = useSelector(DocumentsSelectors.getDocuments)

    const requestSpecificOrder = useCallback(
        (requestDocs: boolean = true) => {
            if (params.id !== undefined) {
                dispatch(
                    OrdersActions.requestSpecificOrder({
                        id: params.id,
                        callback: () => {
                            navigate(Links.Offers)
                        },
                    })
                )
                requestDocs && dispatch(DocumentsActions.requestDocuments())
                dispatch(OrdersActions.setOfferOrders(undefined))
            } else {
                navigate(Links.Offers)
            }
        },
        [params.id]
    )

    const columns = useOrdersColumns(true, false, () => requestSpecificOrder())

    useEffect(() => {
        requestSpecificOrder()
    }, [params.id])

    useEffect(() => {
        requestSpecificOrder(false)
    }, [documents])

    const mappings: Mappings = useMemo(
        () => [
            {
                text: localize('offer'),
                key: 'offer',
                mapping: (offer: Offer) => (
                    <Space size='middle'>
                        <Link to={Links.ViewOffer.replace(':id', offer?.id!)}>
                            {offer?.id!.substring(0, 6)}
                        </Link>
                    </Space>
                ),
            },
            {
                text: localize('counterparty'),
                key: 'counterparty',
                mapping: (counterparty: User) => counterparty.login,
            },
            {
                text: localize('score'),
                key: 'score',
                mapping: (value: number) => value?.toString(),
            },
            {
                text: localize('date'),
                key: 'createdAt',
                mapping: (value: Moment) =>
                    value.format(DATE_TIME_FORMAT).toString(),
            },
            {
                text: localize('state'),
                key: 'state',
                mapping: (state: OrderState) => (
                    <Tag color={orderStateMapping[state].color}>
                        {localize(orderStateMapping[state].text)}
                    </Tag>
                ),
            },
            {
                text: localize('type'),
                key: 'type',
                mapping: (type: OfferType) => (
                    <Tag color={type === OfferType.Ask ? 'blue' : 'red'}>
                        {(type === OfferType.Ask
                            ? localize('ask')
                            : localize('bid')
                        ).toUpperCase()}
                    </Tag>
                ),
            },
            {
                text: localize('amount'),
                key: 'amount',
                mapping: (value: string) => `${value} ${localize('currency')}`,
            },
            {
                text: localize('loanRate'),
                key: 'loanRate',
                mapping: (value: string) => `${value}%`,
            },
            {
                text: localize('penaltyRate'),
                key: 'penaltyRate',
                mapping: (value: string) => `${value}%`,
            },
            {
                text: localize('loanTerm'),
                key: 'loanTerm',
                mapping: (value: string) =>
                    `${value} ${pluralize(Number.parseInt(value), 'dayOne', 'dayTwo', 'dayFive')}`,
            },
        ],
        [localize]
    )

    return (
        <div
            className={classNames(styles.pageContainer, {
                [styles.pageContainerMobile]: isMobile,
            })}
            ref={ref}
        >
            <PageTitle
                title={formatLocalize('orderTitle', {
                    id: params.id?.substring(0, 6) || '',
                })}
                icon={<InfoCircleOutlined />}
                button={
                    specificOffer ? (
                        <div className={styles.buttonsWrapper}>
                            {columns &&
                                (columns[columns.length - 1]?.render?.(
                                    undefined,
                                    specificOffer,
                                    0
                                ) as ReactNode)}
                        </div>
                    ) : undefined
                }
            />
            <div className={styles.details}>
                {specificOffer &&
                    mappings.map((x) => (
                        <div key={x.key} className={styles.detailsRow}>
                            <Typography.Text strong>{x.text}:</Typography.Text>
                            <Typography.Text>
                                {x.mapping(specificOffer[x.key])}
                            </Typography.Text>
                        </div>
                    ))}
            </div>
        </div>
    )
}
