import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DocumentsState, Document, SpecificDocument } from './types'
import {
    WithCallback,
    WithCode,
    WithLocalize,
    WithObjectId,
} from '../../../@types/common'

export const initialState: DocumentsState = {
    documents: undefined,
    documentsLoading: false,
    specificDocument: undefined,
    specificDocumentLoading: false,
    sentOnPhone: undefined,
}

const documentsSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        requestSpecificDocument: (
            state,
            action: PayloadAction<WithObjectId<{}>>
        ) => {},
        declineDocument: (
            state,
            action: PayloadAction<WithObjectId<WithCallback<WithLocalize<{}>>>>
        ) => {},
        requestDocuments: (state, action: PayloadAction) => {},
        setDocuments: (state, action: PayloadAction<Document[]>) => {
            state.documents = action.payload
        },
        setDocumentsLoading: (state, action: PayloadAction<boolean>) => {
            state.documentsLoading = action.payload
        },
        setSpecificDocument: (
            state,
            action: PayloadAction<SpecificDocument | undefined>
        ) => {
            state.specificDocument = action.payload
        },
        setSpecificDocumentLoading: (state, action: PayloadAction<boolean>) => {
            state.specificDocumentLoading = action.payload
        },
        requestSendSms: (
            state,
            action: PayloadAction<WithLocalize<WithObjectId<{}>>>
        ) => {},
        signWithSms: (
            state,
            action: PayloadAction<
                WithCode<WithCallback<WithLocalize<WithObjectId<{}>>>>
            >
        ) => {},
        setSentOnPhone: (state, action: PayloadAction<string | undefined>) => {
            state.sentOnPhone = action.payload
        },
    },
})

export const DocumentsActions = documentsSlice.actions

interface DocumentsReducerState {
    documentsReducer: DocumentsState
}

export const DocumentsSelectors = {
    getDocuments: (state: DocumentsReducerState) =>
        state.documentsReducer.documents,
    getDocumentsLoading: (state: DocumentsReducerState) =>
        state.documentsReducer.documentsLoading,
    getSpecificDocument: (state: DocumentsReducerState) =>
        state.documentsReducer.specificDocument,
    getSpecificDocumentLoading: (state: DocumentsReducerState) =>
        state.documentsReducer.specificDocumentLoading,
    getSentOnPhone: (state: DocumentsReducerState) =>
        state.documentsReducer.sentOnPhone,
}

export default documentsSlice.reducer
