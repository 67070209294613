import { ApiResponse, ApisauceInstance } from 'apisauce'
import { AxiosRequestConfig } from 'axios'
import { Dictionary } from '../../@types/common'
import { getAuthHeader } from './auth'
import { throwErrorNotification } from '../../components/NotificationError/ThrowErrorNotification'
import { REFERRAL_KEY } from '../../constants'

export const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Authorization: getAuthHeader(),
}

export enum RequestMethod {
    GET = 'get',
    POST = 'post',
    DELETE = 'delete',
    PUT = 'put',
    PATCH = 'patch',
}

export interface RequestParams {
    headers?: Dictionary
    instance?: ApisauceInstance
    path: string
    params?: any
    method?: RequestMethod
    data?: any
}

type RequestCreate = (
    api: () => ApisauceInstance
) => <T>(
    params: RequestParams,
    axiosConfig?: AxiosRequestConfig
) => Promise<ApiResponse<T, T>>

export const requestCreate: RequestCreate =
    (api: () => ApisauceInstance) =>
    async <T>(
        {
            path = '',
            params = {},
            method = RequestMethod.GET,
            headers = {},
            instance = api(),
            data = undefined,
        } = {},
        axiosConfig = {}
    ) => {
        const reqParams: any = {
            // TODO: fix
            ...axiosConfig,
            headers: { ...instance.headers, ...headers },
        }

        instance.addMonitor((response) => {
            if (!response.ok && response.data.jwtError !== true) {
                if (response.data.message) {
                    throwErrorNotification(
                        'Api Error',
                        response.data.message,
                        10
                    )
                } else {
                    console.log('Plain error', response.data)

                    // TODO: Move to locale file
                    const errorDict = {
                        'User already exists':
                            'Этот пользователь уже зарегистрирован',
                        'Wrong credentials':
                            'Неверные имя пользователя или пароль',
                        'You are not allowed to make an order for this offer':
                            'Вы не можете откликнуться на эту заявку',
                        'Wrong referral': 'Неверный реферальный код',
                        default:
                            'Произошла техническая ошибка. Обратитесь в службу поддержки платформы.',
                    }
                    const errorByKey = Object.entries(errorDict).filter(
                        (x) =>
                            x[0].toLowerCase() ===
                            (response.data.message?.toLowerCase() || '')
                    )

                    if (
                        errorByKey.length > 0 &&
                        errorByKey[0][0] === 'Wrong referral'
                    ) {
                        localStorage.removeItem(REFERRAL_KEY)
                        window.location.reload()
                    }

                    const errorDefault = errorDict['default']
                    console.log(response.data.message?.toLowerCase())
                    const errorLocalized =
                        errorByKey.length > 0 ? errorByKey[0][1] : errorDefault

                    throwErrorNotification('Предупреждение', errorLocalized, 10)
                }
            }
        })

        if (data) {
            reqParams.data = data
        }

        return instance[method]<T>(path, params, reqParams)
    }
