import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useLocalizationContext, useMobile } from '../../utils/contexts'
import classNames from 'classnames'
import styles from './ViewOfferPage.module.scss'
import { CustomPagination, PageTitle, ResponsiveTable } from '../../components'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { Links } from '../../constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { OffersActions, OffersSelectors } from '../../redux/reducers/offers'
import { HomeSelectors } from '../../redux/reducers/home'
import { Button, TableProps, Tag, Typography } from 'antd'
import { Offer, OfferState, OfferType } from '../../redux/reducers/offers/types'
import { User } from '../../redux/reducers/home/types'
import { OrdersActions, OrdersSelectors } from '../../redux/reducers/orders'
import { Moment } from 'moment'
import { DATE_TIME_FORMAT } from '../../constants'
import { useOrdersColumns } from '../OrdersPage/OrdersColumns'
import { OFFER_ASK_RESPOND, OFFER_BID_RESPOND } from '../../@types/accesses'
import {
    DocumentsActions,
    DocumentsSelectors,
} from '../../redux/reducers/documents'
import { Order } from '../../redux/reducers/orders/types'
import { SorterResult } from 'antd/es/table/interface'
import { FiltersType, SortOrder, WithSortAndFilters } from '../../@types/common'

type Mappings = {
    key: keyof Offer
    text: string
    mapping: (value: any) => React.ReactElement | string
}[]

export const offerStateMappings = {
    [OfferState.NotSigned]: {
        color: 'gray',
        text: 'notSigned',
    },
    [OfferState.Opened]: {
        color: 'green',
        text: 'opened',
    },
    [OfferState.Closed]: {
        color: 'red',
        text: 'closed',
    },
}

export const ViewOfferPage: FC = () => {
    const dispatch = useDispatch()
    const { formatLocalize, localize, pluralize } = useLocalizationContext()
    const isMobile = useMobile()
    const navigate = useNavigate()
    const params = useParams()
    const ref = useRef<HTMLDivElement>(null)

    const specificOffer = useSelector(OffersSelectors.getSpecificOffer)
    const user = useSelector(HomeSelectors.getUser)
    const orders = useSelector(OrdersSelectors.getOfferOrders)
    const accesses = useSelector(HomeSelectors.getAccesses)
    const documents = useSelector(DocumentsSelectors.getDocuments)

    const [sortAndFilters, setSortAndFilters] = useState<
        WithSortAndFilters<{}>
    >({
        filters: {},
    })

    const onChangeHandler: TableProps<Order>['onChange'] = (
        _,
        filter,
        sorter,
        __
    ) => {
        const sorterCast = sorter as SorterResult<Order>
        setSortAndFilters({
            sortBy: sorterCast.columnKey as string,
            sortOrder: sorterCast.order as SortOrder,
            filters: filter as FiltersType,
        })
    }

    useEffect(() => {
        if (params.id !== undefined) {
            dispatch(
                OffersActions.requestSpecificOffer({
                    id: params.id!,
                    callback: () => {
                        navigate(Links.Offers)
                    },
                })
            )
            dispatch(
                OrdersActions.requestOfferOrders({
                    id: params.id!,
                    ...sortAndFilters,
                })
            )
        }
    }, [documents])

    const columns = useOrdersColumns(
        specificOffer?.owner.id === user?.id ||
            !!orders?.data.find((x) => x.counterparty.id === user?.id),
        false,
        () => {
            dispatch(
                OrdersActions.requestOfferOrders({
                    id: params.id!,
                    ...sortAndFilters,
                })
            )
            dispatch(
                OffersActions.requestSpecificOffer({
                    id: params.id!,
                    callback: () => {
                        navigate(Links.Offers)
                    },
                })
            )
            dispatch(DocumentsActions.requestDocuments())
        }
    )

    useEffect(() => {
        if (params.id !== undefined) {
            dispatch(
                OffersActions.requestSpecificOffer({
                    id: params.id,
                    callback: () => {
                        navigate(Links.Offers)
                    },
                })
            )
            dispatch(OrdersActions.setOfferOrders(undefined))
            dispatch(
                OrdersActions.requestOfferOrders({
                    id: params.id,
                    ...sortAndFilters,
                })
            )
        } else {
            navigate(Links.Offers)
        }
    }, [params.id])

    useEffect(() => {
        if (params.id) {
            dispatch(
                OrdersActions.requestOfferOrders({
                    id: params.id,
                    ...sortAndFilters,
                })
            )
        }
    }, [sortAndFilters])

    const mappings: Mappings = useMemo(
        () => [
            {
                text: localize('creator'),
                key: 'owner',
                mapping: (user: User) => user.login,
            },
            {
                text: localize('date'),
                key: 'updatedAt',
                mapping: (value: Moment) =>
                    value.format(DATE_TIME_FORMAT).toString(),
            },
            {
                text: localize('score'),
                key: 'score',
                mapping: (value: number) => value?.toString(),
            },
            {
                text: localize('state'),
                key: 'state',
                mapping: (type: OfferState) => (
                    <Tag color={offerStateMappings[type].color}>
                        {localize(offerStateMappings[type].text)}
                    </Tag>
                ),
            },
            {
                text: localize('type'),
                key: 'type',
                mapping: (type: OfferType) => (
                    <Tag color={type === OfferType.Ask ? 'blue' : 'red'}>
                        {(type === OfferType.Ask
                            ? localize('ask')
                            : localize('bid')
                        ).toUpperCase()}
                    </Tag>
                ),
            },
            {
                text: localize('amount'),
                key: 'amount',
                mapping: (value: string) => `${value} ${localize('currency')}`,
            },
            {
                text: localize('loanRate'),
                key: 'loanRate',
                mapping: (value: string) => `${value}%`,
            },
            {
                text: localize('penaltyRate'),
                key: 'penaltyRate',
                mapping: (value: string) => `${value}%`,
            },
            {
                text: localize('loanTerm'),
                key: 'loanTerm',
                mapping: (value: string) =>
                    `${value} ${pluralize(Number.parseInt(value), 'dayOne', 'dayTwo', 'dayFive')}`,
            },
        ],
        [localize]
    )

    return (
        <div
            className={classNames(styles.pageContainer, {
                [styles.pageContainerMobile]: isMobile,
            })}
            ref={ref}
        >
            <PageTitle
                title={formatLocalize('offerTitle', {
                    id: params.id?.substring(0, 6) || '',
                })}
                icon={<InfoCircleOutlined />}
                button={
                    specificOffer ? (
                        <div className={styles.buttonsWrapper}>
                            {user?.id === specificOffer?.owner.id ? (
                                <>
                                    <Button
                                        type={'primary'}
                                        disabled={
                                            specificOffer.state ===
                                            OfferState.Closed
                                        }
                                        onClick={() =>
                                            navigate(
                                                (specificOffer?.type ===
                                                OfferType.Ask
                                                    ? Links.EditAskOffer
                                                    : Links.EditBidOffer
                                                ).replace(
                                                    ':id',
                                                    specificOffer?.id!
                                                )
                                            )
                                        }
                                    >
                                        {localize('edit')}
                                    </Button>
                                    <Button
                                        type={'primary'}
                                        danger
                                        disabled={
                                            specificOffer.state ===
                                            OfferState.Closed
                                        }
                                        onClick={() => {
                                            dispatch(
                                                OffersActions.closeOffer({
                                                    localize,
                                                    id: specificOffer?.id!,
                                                    callback: () => {
                                                        navigate(Links.Offers)
                                                    },
                                                })
                                            )
                                        }}
                                    >
                                        {localize('close')}
                                    </Button>
                                </>
                            ) : (
                                <Button
                                    type={'primary'}
                                    danger
                                    disabled={
                                        specificOffer.state ===
                                            OfferState.Closed ||
                                        !!orders?.data.find(
                                            (x) =>
                                                x.counterparty?.id === user?.id
                                        ) ||
                                        (accesses &&
                                            (specificOffer.type ===
                                            OfferType.Ask
                                                ? !accesses[OFFER_ASK_RESPOND]
                                                : !accesses[OFFER_BID_RESPOND]))
                                    }
                                    onClick={() =>
                                        dispatch(
                                            OrdersActions.createOrder({
                                                id: specificOffer?.id!,
                                                localize,
                                                callback: () =>
                                                    dispatch(
                                                        DocumentsActions.requestDocuments()
                                                    ),
                                            })
                                        )
                                    }
                                >
                                    {localize('respond')}
                                </Button>
                            )}
                        </div>
                    ) : undefined
                }
            />
            {orders && orders.total > 0 && (
                <div
                    style={{
                        paddingBottom: 12,
                        display: 'flex',
                        gap: '8px',
                        flexDirection: 'column',
                    }}
                >
                    <Typography.Title level={3}>
                        {localize('orders')}
                    </Typography.Title>
                    <ResponsiveTable
                        rowKey={'id'}
                        columns={columns}
                        dataSource={orders.data}
                        onChange={onChangeHandler}
                        pagination={false}
                    />
                    {orders.total > orders.perPage && (
                        <CustomPagination
                            parentRef={ref}
                            showSizeChanger
                            onChange={(page, perPage) =>
                                dispatch(
                                    OrdersActions.requestOfferOrders({
                                        id: params.id!,
                                        page,
                                        perPage,
                                        ...sortAndFilters,
                                    })
                                )
                            }
                            onShowSizeChange={(page, perPage) => {
                                dispatch(
                                    OrdersActions.requestOfferOrders({
                                        id: params.id!,
                                        page,
                                        perPage,
                                        ...sortAndFilters,
                                    })
                                )
                            }}
                            defaultCurrent={orders.page}
                            total={orders.total}
                        />
                    )}
                </div>
            )}
            <div className={styles.details}>
                {specificOffer &&
                    mappings.map((x) => (
                        <div key={x.key} className={styles.detailsRow}>
                            <Typography.Text strong>{x.text}:</Typography.Text>
                            <Typography.Text>
                                {x.mapping(specificOffer[x.key])}
                            </Typography.Text>
                        </div>
                    ))}
            </div>
        </div>
    )
}
