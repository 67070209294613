import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import styles from './ReferralPage.module.scss'
import { CustomPagination, PageTitle, ResponsiveTable } from '../../components'
import { UsergroupAddOutlined } from '@ant-design/icons'
import { useLocalizationContext, useMobile } from '../../utils/contexts'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input, Modal, Space, TableProps, Tag, Typography } from 'antd'
import {
    ReferralBalanceActions,
    ReferralBalanceSelectors,
} from '../../redux/reducers/referralBalance'
import { throwNotification } from '../../components/Notification'
import { NoticeTypes } from '../../@types/notifications'
import { Moment } from 'moment/moment'
import { DATE_TIME_FORMAT } from '../../constants'
import {
    ReferralBalance,
    ReferralBalanceOperation,
} from '../../redux/reducers/referralBalance/types'

const { Text } = Typography

export const ReferralPage: FC = () => {
    const dispatch = useDispatch()
    const isMobile = useMobile()
    const ref = useRef<HTMLDivElement>(null)
    const { localize } = useLocalizationContext()
    const minBalance = useSelector(ReferralBalanceSelectors.getMinBalance)
    const info = useSelector(ReferralBalanceSelectors.getInfo)
    const balanceList = useSelector(ReferralBalanceSelectors.getBalanceList)
    const [page, setPage] = useState<number>()
    const [perPage, setPerPage] = useState<number>()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [withdrawalAmount, setWithdrawalAmount] = useState(0)

    useEffect(() => {
        dispatch(ReferralBalanceActions.requestMinBalance())
        dispatch(ReferralBalanceActions.requestReferralInfo())
    }, [dispatch])

    const referralLink = useMemo(
        () =>
            info?.referralCode &&
            `${window.location.origin}/?ref=${info.referralCode}`,
        [info?.referralCode]
    )

    useEffect(() => {
        dispatch(
            ReferralBalanceActions.requestReferralBalance({ page, perPage })
        )
    }, [dispatch, page, perPage])

    const columns: TableProps<ReferralBalance>['columns'] = useMemo(
        () => [
            {
                title: localize('amount'),
                dataIndex: 'amount',
                key: 'amount',
                render: (amount) => `${amount} ${localize('currency')}`,
            },
            {
                title: localize('balance'),
                dataIndex: 'balanceAfter',
                key: 'balanceAfter',
                render: (amount) => `${amount} ${localize('currency')}`,
            },
            {
                title: localize('type'),
                dataIndex: 'type',
                key: 'type',
                render: (type: ReferralBalanceOperation) => (
                    <Tag
                        color={
                            type === ReferralBalanceOperation.Payout
                                ? 'green'
                                : 'red'
                        }
                    >
                        {(type === ReferralBalanceOperation.Payout
                            ? localize('payout')
                            : localize('withdrawal')
                        ).toUpperCase()}
                    </Tag>
                ),
            },
            {
                title: localize('comment'),
                dataIndex: 'comment',
                key: 'comment',
                render: (value: string) => value,
            },
            {
                title: localize('date'),
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (value: Moment) => value.format(DATE_TIME_FORMAT),
            },
        ],
        [localize]
    )

    const onModalClose = () => {
        setIsModalOpen(false)
        setWithdrawalAmount(0)
    }

    return (
        <div
            className={classNames(styles.pageContainer, {
                [styles.pageContainerMobile]: isMobile,
            })}
            ref={ref}
        >
            <PageTitle
                title={localize('referralTitle')}
                icon={<UsergroupAddOutlined />}
                button={
                    <Button
                        disabled={(info?.balance || 0) < (minBalance || 0)}
                        onClick={() => setIsModalOpen(true)}
                    >
                        {localize('referralWithdrawal')}
                    </Button>
                }
            />
            <Space direction={'horizontal'}>
                {info !== undefined && (
                    <>
                        <Space direction={'vertical'} style={{ width: '100%' }}>
                            <Text>{localize('balance')}</Text>
                            <Input
                                disabled
                                value={`${info.balance} ${localize('currency')}`}
                                style={{ width: '100%' }}
                            />
                        </Space>
                        {info.referral && (
                            <Space
                                direction={'vertical'}
                                style={{ width: '100%' }}
                            >
                                <Text>{localize('invitedBy')}</Text>
                                <Input
                                    disabled
                                    value={info.referral}
                                    style={{ width: '100%' }}
                                />
                            </Space>
                        )}
                        <Space direction={'vertical'} style={{ width: '100%' }}>
                            <Text>{localize('referralCode')}</Text>
                            <Text
                                className={styles.link}
                                onClick={() => {
                                    if (referralLink) {
                                        navigator.clipboard.writeText(
                                            referralLink
                                        )
                                        throwNotification(
                                            NoticeTypes.Success,
                                            localize('copied')
                                        )
                                    }
                                }}
                            >
                                {referralLink}
                            </Text>
                        </Space>
                    </>
                )}
            </Space>
            <ResponsiveTable
                rowKey={'id'}
                columns={columns}
                dataSource={balanceList?.data || []}
                pagination={false}
            />
            {balanceList && (
                <CustomPagination
                    parentRef={ref}
                    showSizeChanger
                    onChange={(page) => setPage(page)}
                    onShowSizeChange={(page, perPage) => setPerPage(perPage)}
                    defaultCurrent={balanceList.page}
                    total={balanceList.total}
                />
            )}
            <Modal
                title={localize('referralWithdrawal')}
                open={isModalOpen}
                onOk={() => {
                    dispatch(
                        ReferralBalanceActions.requestWithdrawal({
                            amount: withdrawalAmount,
                            callback: () => {
                                onModalClose()
                                dispatch(
                                    ReferralBalanceActions.requestReferralBalance(
                                        { page, perPage }
                                    )
                                )
                                dispatch(
                                    ReferralBalanceActions.requestReferralInfo()
                                )
                            },
                            localize,
                        })
                    )
                }}
                onCancel={() => {
                    onModalClose()
                }}
                okButtonProps={{
                    disabled:
                        !withdrawalAmount ||
                        withdrawalAmount < (minBalance || 0),
                }}
                okText={localize('withdraw')}
                cancelText={localize('cancel')}
            >
                <Space direction={'vertical'} style={{ width: '100%' }}>
                    <Text>{localize('amount')}</Text>
                    <Input
                        value={withdrawalAmount}
                        type='number'
                        onChange={(e) =>
                            setWithdrawalAmount(Number.parseInt(e.target.value))
                        }
                        style={{ width: '100%' }}
                    />
                </Space>
            </Modal>
        </div>
    )
}
