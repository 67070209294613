import { Moment } from 'moment'
import { Offer, OfferType } from '../offers/types'
import { User } from '../home/types'
import { Paginated } from '../../../@types/common'

export type OrdersState = {
    orders?: Paginated<Order>
    ordersLoading: boolean
    offerOrders?: Paginated<Order>
    offerOrdersLoading: boolean
    specificOrder?: Order
    specificOrderLoading: boolean
    paymentLoading: boolean
}

export enum OrderState {
    NotSigned,
    Draft,
    Rejected,
    Active,
    Expired,
    Closed,
    Approval,
    PaymentAwaiting,
}

export type Order = {
    createdAt: Moment
    deletedAt?: Moment
    id: string | null
    updatedAt: Moment
    amount: number
    penaltyRate: number
    loanTerm: number
    loanRate: number
    state: OrderState
    score?: number
    counterparty: User
    type: OfferType
    offer: Offer
}
