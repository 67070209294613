import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Paginated, WithCallback, WithLocalize } from '../../../@types/common'
import {
    ReferralBalance,
    ReferralBalanceState,
    ReferralWithdrawalPayload,
    UserReferralInfoResult,
} from './types'

export const initialState: ReferralBalanceState = {
    referralBalance: undefined,
    referralInfo: undefined,
    referralInfoLoading: false,
    referralBalanceLoading: false,
    minBalance: undefined,
}

const referralBalanceSlice = createSlice({
    name: 'referralBalance',
    initialState,
    reducers: {
        requestReferralBalance: (
            state,
            action: PayloadAction<Partial<Paginated<{}>>>
        ) => {},
        requestReferralInfo: (state) => {},
        requestMinBalance: (state) => {},
        requestWithdrawal: (
            state,
            action: PayloadAction<
                WithLocalize<WithCallback<ReferralWithdrawalPayload>>
            >
        ) => {},
        setReferralBalance: (
            state,
            action: PayloadAction<Paginated<ReferralBalance> | undefined>
        ) => {
            state.referralBalance = action.payload
        },
        setReferralBalanceLoading: (state, action: PayloadAction<boolean>) => {
            state.referralBalanceLoading = action.payload
        },
        setReferralInfo: (
            state,
            action: PayloadAction<UserReferralInfoResult | undefined>
        ) => {
            state.referralInfo = action.payload
        },
        setReferralInfoLoading: (state, action: PayloadAction<boolean>) => {
            state.referralInfoLoading = action.payload
        },
        setMinBalance: (state, action: PayloadAction<number | undefined>) => {
            state.minBalance = action.payload
        },
    },
})

export const ReferralBalanceActions = referralBalanceSlice.actions

interface ReferralBalanceReducerState {
    referralBalanceReducer: ReferralBalanceState
}

export const ReferralBalanceSelectors = {
    getBalanceList: (state: ReferralBalanceReducerState) =>
        state.referralBalanceReducer.referralBalance,
    getBalanceListLoading: (state: ReferralBalanceReducerState) =>
        state.referralBalanceReducer.referralBalanceLoading,
    getInfo: (state: ReferralBalanceReducerState) =>
        state.referralBalanceReducer.referralInfo,
    getInfoLoading: (state: ReferralBalanceReducerState) =>
        state.referralBalanceReducer.referralInfoLoading,
    getMinBalance: (state: ReferralBalanceReducerState) =>
        state.referralBalanceReducer.minBalance,
}

export default referralBalanceSlice.reducer
