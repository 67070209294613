import { Moment } from 'moment'
import { Paginated } from '../../../@types/common'

export type ReferralBalanceState = {
    referralBalance?: Paginated<ReferralBalance>
    referralBalanceLoading: boolean
    referralInfo?: UserReferralInfoResult
    referralInfoLoading: boolean
    minBalance?: number
}

export enum ReferralBalanceOperation {
    Payout,
    Withdrawal,
}

export type UserReferralInfoResult = {
    referral?: string
    referralCode: string
    balance: number
}

export type ReferralBalance = {
    createdAt: Moment
    deletedAt?: Moment
    updatedAt?: Moment
    id: string | null
    amount: number
    balanceAfter: number
    comment: string
    type: ReferralBalanceOperation
}

export type ReferralWithdrawalPayload = {
    amount: number
}
