import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    Paginated,
    WithCallback,
    WithLocalize,
    WithObjectId,
    WithQuery,
    WithSortAndFilters,
} from '../../../@types/common'
import { Order, OrdersState } from './types'

export const initialState: OrdersState = {
    orders: undefined,
    ordersLoading: false,
    offerOrders: undefined,
    offerOrdersLoading: false,
    specificOrder: undefined,
    specificOrderLoading: false,
    paymentLoading: false,
}

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        requestOrders: (
            state,
            action: PayloadAction<
                WithSortAndFilters<WithQuery<Partial<Paginated<{}>>>>
            >
        ) => {},
        requestSpecificOrder: (
            state,
            action: PayloadAction<WithCallback<WithObjectId<{}>>>
        ) => {},
        requestOfferOrders: (
            state,
            action: PayloadAction<
                WithSortAndFilters<WithObjectId<Partial<Paginated<{}>>>>
            >
        ) => {},
        createOrder: (
            state,
            action: PayloadAction<WithCallback<WithLocalize<WithObjectId<{}>>>>
        ) => {},
        payForOrder: (
            state,
            action: PayloadAction<WithCallback<WithLocalize<WithObjectId<{}>>>>
        ) => {},
        setOrders: (
            state,
            action: PayloadAction<Paginated<Order> | undefined>
        ) => {
            state.orders = action.payload
        },
        setOrdersLoading: (state, action: PayloadAction<boolean>) => {
            state.ordersLoading = action.payload
        },
        setPaymentLoading: (state, action: PayloadAction<boolean>) => {
            state.paymentLoading = action.payload
        },
        setOfferOrders: (
            state,
            action: PayloadAction<Paginated<Order> | undefined>
        ) => {
            state.offerOrders = action.payload
        },
        setOfferOrdersLoading: (state, action: PayloadAction<boolean>) => {
            state.offerOrdersLoading = action.payload
        },
        setSpecificOrder: (state, action: PayloadAction<Order | undefined>) => {
            state.specificOrder = action.payload
        },
        setSpecificOrderLoading: (state, action: PayloadAction<boolean>) => {
            state.specificOrderLoading = action.payload
        },
    },
})

export const OrdersActions = ordersSlice.actions

interface OrdersReducerState {
    ordersReducer: OrdersState
}

export const OrdersSelectors = {
    getOrders: (state: OrdersReducerState) => state.ordersReducer.orders,
    getOrdersLoading: (state: OrdersReducerState) =>
        state.ordersReducer.ordersLoading,
    getOfferOrders: (state: OrdersReducerState) =>
        state.ordersReducer.offerOrders,
    getOfferOrdersLoading: (state: OrdersReducerState) =>
        state.ordersReducer.offerOrdersLoading,
    getSpecificOrder: (state: OrdersReducerState) =>
        state.ordersReducer.specificOrder,
    getSpecificOrderLoading: (state: OrdersReducerState) =>
        state.ordersReducer.specificOrderLoading,
    getPaymentLoading: (state: OrdersReducerState) =>
        state.ordersReducer.paymentLoading,
}

export default ordersSlice.reducer
