import React, { FC, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import styles from './LeaderboardPage.module.scss'
import { PageTitle, ResponsiveTable } from '../../components'
import { BarChartOutlined } from '@ant-design/icons'
import { useLocalizationContext, useMobile } from '../../utils/contexts'
import { useDispatch, useSelector } from 'react-redux'
import { TableProps } from 'antd'
import { HomeActions, HomeSelectors } from '../../redux/reducers/home'
import { LeaderboardEntity } from '../../redux/reducers/home/types'

export const LeaderboardPage: FC = () => {
    const dispatch = useDispatch()
    const isMobile = useMobile()
    const { localize } = useLocalizationContext()
    const leaderboard = useSelector(HomeSelectors.getLeaderboard)

    useEffect(() => {
        dispatch(HomeActions.requestLeaderboard())
    }, [dispatch])

    const columns: TableProps<LeaderboardEntity>['columns'] = useMemo(
        () => [
            {
                title: localize('username'),
                dataIndex: 'user',
                key: 'user',
                render: (value: string) => value,
            },
            {
                title: localize('income'),
                dataIndex: 'profit',
                key: 'profit',
                render: (value: string) => `${value} ${localize('currency')}`,
            },
        ],
        [localize]
    )

    return (
        <div
            className={classNames(styles.pageContainer, {
                [styles.pageContainerMobile]: isMobile,
            })}
        >
            <PageTitle
                title={localize('leaderboard')}
                icon={<BarChartOutlined />}
            />
            <ResponsiveTable
                rowKey={'user'}
                columns={columns}
                dataSource={leaderboard || []}
                pagination={false}
            />
        </div>
    )
}
