import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    HomeState,
    LeaderboardEntity,
    LoginRequestPayload,
    RegisterRequestPayload,
    RegStatus,
    TokenDto,
    User,
    UserScore,
} from './types'
import {
    Dictionary,
    Language,
    WithCallback,
    WithLocalize,
} from '../../../@types/common'

export const initialState: HomeState = {
    isLoggedIn: false,
    locale: Language.Russian,
    user: undefined,
    accesses: undefined,
    isInitialized: false,
    bepaidAuthParams: undefined,
    regStatus: undefined,
    userScore: undefined,
    leaderboard: undefined,
    leaderboardLoading: false,
}

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        requestSignIn: (
            state,
            action: PayloadAction<WithLocalize<LoginRequestPayload>>
        ) => {},
        requestScore: (state) => {},
        requestRegStatus: (state, action: PayloadAction) => {},
        requestLeaderboard: (state, action: PayloadAction) => {},
        requestUserAccesses: (state, action: PayloadAction) => {},
        requestRegisterUser: (
            state,
            action: PayloadAction<WithLocalize<RegisterRequestPayload>>
        ) => {},
        createToken: (
            state,
            action: PayloadAction<WithCallback<TokenDto>>
        ) => {},
        requestBepaidAuthParams: (
            state,
            action: PayloadAction<WithCallback<{}>>
        ) => {},
        requestGetCurrentUser: (state) => {},
        setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload
        },
        setUser: (state, action: PayloadAction<User | undefined>) => {
            state.user = action.payload
        },
        setLeaderboard: (
            state,
            action: PayloadAction<LeaderboardEntity[] | undefined>
        ) => {
            state.leaderboard = action.payload
        },
        setLeaderboardLoading: (state, action: PayloadAction<boolean>) => {
            state.leaderboardLoading = action.payload
        },
        setBepaidAuthParams: (
            state,
            action: PayloadAction<Object | undefined>
        ) => {
            state.bepaidAuthParams = action.payload
        },
        setAccesses: (
            state,
            action: PayloadAction<Dictionary<boolean> | undefined>
        ) => {
            state.accesses = action.payload
        },
        setScore: (state, action: PayloadAction<UserScore | undefined>) => {
            state.userScore = action.payload
        },
        setIsInitialized: (state, action: PayloadAction<boolean>) => {
            state.isInitialized = action.payload
        },
        setLocale: (state, action: PayloadAction<Language>) => {
            state.locale = action.payload
        },
        setRegStatus: (state, action: PayloadAction<RegStatus | undefined>) => {
            state.regStatus = action.payload
        },
    },
})

export const HomeActions = homeSlice.actions

interface HomeReducerState {
    homeReducer: HomeState
}

export const HomeSelectors = {
    getIsLoggedIn: (state: HomeReducerState) => state.homeReducer.isLoggedIn,
    getUser: (state: HomeReducerState) => state.homeReducer.user,
    getIsInitialized: (state: HomeReducerState) =>
        state.homeReducer.isInitialized,
    getLocale: (state: HomeReducerState) => state.homeReducer.locale,
    getAccesses: (state: HomeReducerState) => state.homeReducer.accesses,
    getBepaidAuthParams: (state: HomeReducerState) =>
        state.homeReducer.bepaidAuthParams,
    getRegStatus: (state: HomeReducerState) => state.homeReducer.regStatus,
    getUserScore: (state: HomeReducerState) => state.homeReducer.userScore,
    getLeaderboard: (state: HomeReducerState) => state.homeReducer.leaderboard,
    getLeaderboardLoading: (state: HomeReducerState) =>
        state.homeReducer.leaderboardLoading,
}

export default homeSlice.reducer
