import { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import styles from './MsiDataPage.module.scss'
import { PageTitle } from '../../components'
import { FileOutlined } from '@ant-design/icons'
import { useLocalizationContext, useMobile } from '../../utils/contexts'
import { useSelector } from 'react-redux'
import { MsiSelectors } from '../../redux/reducers/msi'
import { Button, Input, Space, Typography } from 'antd'
import { IdDocumentType } from '../../redux/reducers/msi/types'
import { useNavigate } from 'react-router-dom'
import { Links } from '../../constants/routes'
import { Alert } from 'antd'
import { HomeSelectors } from '../../redux/reducers/home'
import {
    UserErrorModel,
    UserIndividualModel,
    UserLegalModel,
    UserType,
} from '../../openapi/identification/data-contracts'
import moment from 'moment'
import { DATE_FORMAT } from '../../constants'
import { USER_TYPE } from '../../@types/accesses'
const { Text, Title } = Typography

export const isDataComplete = (data: UserErrorModel) => {
    return !data.message || data.message?.length === 0
}

export const isLegalDataComplete = (data: UserErrorModel) => {
    return !data.message || data.message?.length === 0
}

const idDocumentMappings = {
    [IdDocumentType.BelarusPassport]: 'Паспорт Республики Беларусь',
    [IdDocumentType.BelarusResidentCard]:
        'Вид на жительство Республики Беларусь',
    [IdDocumentType.IdCardBelarus]:
        'Идентификационная карта Республики Беларусь',
    [IdDocumentType.BiometricResidentCard]:
        'Биометрический вид на жительство в Республике Беларусь иностранного гражданина',
    [IdDocumentType.BiometricResidentCardWithoutCitizenship]:
        'Биометрический вид на жительство в Республике Беларусь лица без гражданства',
}

const idLegalTypeMapping = {
    '': 'Не определено',
    business: 'Юридическое Лицо',
    entrepreneur: 'Индивидуальный Предприниматель',
    government: 'Орган Государственной Власти и Управления',
}

export const MsiDataPage: FC = () => {
    const isMobile = useMobile()
    const navigate = useNavigate()
    const { localize } = useLocalizationContext()
    const msiRawData = useSelector(MsiSelectors.getMsiData)
    const msiDataLoading = useSelector(MsiSelectors.getMsiDataLoading)
    const accesses = useSelector(HomeSelectors.getAccesses)
    const [isLoaded, setIsLoaded] = useState(false)

    const [dataIncomplete, setDataIncomplete] = useState(false)
    const [dataEnabled, setDataEnabled] = useState(true)
    const [msiData, setMsiData] = useState<UserIndividualModel | null>(null)
    const [msiLegalData, setMsiLegalData] = useState<UserLegalModel | null>(
        null
    )

    const extraRender = false

    useEffect(() => {
        if (
            msiRawData?.individual &&
            accesses &&
            accesses[USER_TYPE] === UserType.Individual
        ) {
            setIsLoaded(true)
            setDataIncomplete(!isDataComplete(msiRawData))
            setDataEnabled(msiRawData.enabled === true)
            setMsiData(msiRawData.individual)
        } else if (msiRawData?.legal && accesses) {
            setIsLoaded(true)
            setDataIncomplete(!isLegalDataComplete(msiRawData))
            setDataEnabled(msiRawData.enabled === true)
            setMsiLegalData(msiRawData.legal)
        }
    }, [msiRawData, setMsiData, setMsiLegalData, accesses])

    return (
        <div
            className={classNames(styles.pageContainer, {
                [styles.pageContainerMobile]: isMobile,
            })}
        >
            <PageTitle title={'Данные из МСИ'} icon={<FileOutlined />} />
            {isLoaded &&
                accesses &&
                accesses[USER_TYPE] === UserType.Individual && (
                    <>
                        {!dataEnabled && (
                            <Alert
                                type='error'
                                banner={true}
                                message='Проблема с идентификацией. Обратитесь в службу поддержки платформы.'
                            ></Alert>
                        )}
                        {dataIncomplete && (
                            <Alert
                                type='warning'
                                banner={true}
                                message='Отсутствуют обязательные данные для идентификации. Обратитесь в службу поддержки платформы.'
                            ></Alert>
                        )}
                        {msiData ? (
                            <Space
                                direction={'vertical'}
                                style={{ width: '100%' }}
                            >
                                <Title level={3}>Личные данные</Title>
                                <Space
                                    direction={'vertical'}
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Фамилия:</Text>
                                        <Input
                                            disabled
                                            value={msiData.lastName || ''}
                                        />
                                    </Space>
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Имя:</Text>
                                        <Input
                                            disabled
                                            value={msiData.firstName || ''}
                                        />
                                    </Space>
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Отчество:</Text>
                                        <Input
                                            disabled
                                            value={msiData.secondName || ''}
                                        />
                                    </Space>
                                </Space>
                                {extraRender && (
                                    <Space direction={'horizontal'}>
                                        <Space direction={'vertical'}>
                                            <Text>Дата рождения:</Text>
                                            <Input disabled value={''} />
                                        </Space>
                                    </Space>
                                )}
                                {extraRender && msiData.documentId && (
                                    <Space
                                        direction={
                                            isMobile ? 'vertical' : 'horizontal'
                                        }
                                        style={{ width: '100%' }}
                                    >
                                        <Space
                                            direction={'vertical'}
                                            style={{ width: '100%' }}
                                        >
                                            <Text>Гражданство:</Text>
                                            <Input
                                                disabled
                                                value={
                                                    ''
                                                    /*
                                                countries.data.find(
                                                    (x) =>
                                                        x.code ===
                                                        msiData.data.id_document
                                                            .country
                                                )?.fullname
                                                */
                                                }
                                                style={{ width: '100%' }}
                                            />
                                        </Space>
                                        <Space
                                            direction={'vertical'}
                                            style={{ width: '100%' }}
                                        >
                                            <Text>
                                                Резидент Республики Беларусь:
                                            </Text>
                                            <Input
                                                disabled
                                                value={
                                                    msiData.resident
                                                        ? 'Да'
                                                        : 'Нет'
                                                }
                                                style={{ width: '100%' }}
                                            />
                                        </Space>
                                    </Space>
                                )}
                                <br />
                                <Title level={3}>
                                    Информация о документе, удостоверяющем
                                    личность
                                </Title>
                                {msiData.documentId && (
                                    <Space
                                        direction={'horizontal'}
                                        style={{ width: '100%' }}
                                    >
                                        <Space
                                            direction={'vertical'}
                                            style={{ width: '100%' }}
                                        >
                                            <Text>
                                                Вид документа, удостоверяющего
                                                личность
                                            </Text>
                                            <Input
                                                disabled
                                                value={
                                                    (idDocumentMappings as any)[
                                                        msiData.documentType
                                                    ]
                                                }
                                                style={{ width: '100%' }}
                                            />
                                        </Space>
                                    </Space>
                                )}
                                {extraRender && msiData.documentId && (
                                    <Space
                                        direction={
                                            isMobile ? 'vertical' : 'horizontal'
                                        }
                                        style={{ width: '100%' }}
                                    >
                                        <Space
                                            direction={'vertical'}
                                            style={{ width: '100%' }}
                                        >
                                            <Text>Страна выдачи</Text>
                                            <Input
                                                disabled
                                                value={
                                                    ''
                                                    /*
                                                countries.data.find(
                                                    (x) =>
                                                        x.code ===
                                                        msiData.data.id_document.country
                                                )?.fullname
                                                */
                                                }
                                                style={{ width: '100%' }}
                                            />
                                        </Space>
                                    </Space>
                                )}
                                {msiData.documentId && (
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Space
                                            direction={'vertical'}
                                            style={{ width: '100%' }}
                                        >
                                            <Text>
                                                Орган, выдавший документ,
                                                удостоверяющий личность
                                            </Text>
                                            <Input
                                                disabled
                                                value={
                                                    msiData.documentAuthority
                                                }
                                                style={{ width: '100%' }}
                                            />
                                        </Space>
                                        <Space
                                            direction={'vertical'}
                                            style={{ width: '100%' }}
                                        >
                                            <Text>
                                                Реквизиты документа,
                                                удостоверяющего личность
                                                (идентификационный номер)
                                            </Text>
                                            <Input
                                                disabled
                                                value={msiData.documentId}
                                                style={{ width: '100%' }}
                                            />
                                        </Space>
                                    </Space>
                                )}
                                {msiData.documentId && (
                                    <Space
                                        direction={
                                            isMobile ? 'vertical' : 'horizontal'
                                        }
                                        style={{ width: '100%' }}
                                    >
                                        <Space
                                            direction={'vertical'}
                                            style={{ width: '100%' }}
                                        >
                                            <Text>Серия и Номер</Text>
                                            <Input
                                                disabled
                                                value={msiData.documentNumber}
                                                style={{ width: '100%' }}
                                            />
                                        </Space>
                                    </Space>
                                )}
                                {msiData.documentId && (
                                    <Space
                                        direction={'horizontal'}
                                        style={{ width: '100%' }}
                                    >
                                        <Space
                                            direction={'vertical'}
                                            style={{ width: '100%' }}
                                        >
                                            <Text>Дата выдачи</Text>
                                            <Input
                                                disabled
                                                value={
                                                    !!msiData.documentIssueDate &&
                                                    msiData.documentIssueDate
                                                        .toString()
                                                        .toLowerCase() !==
                                                        'invalid date'
                                                        ? moment(
                                                              msiData.documentIssueDate
                                                          ).format(DATE_FORMAT)
                                                        : ''
                                                }
                                                style={{ width: '100%' }}
                                            />
                                        </Space>
                                        <Space
                                            direction={'vertical'}
                                            style={{ width: '100%' }}
                                        >
                                            <Text>Срок действия</Text>
                                            <Input
                                                disabled
                                                value={
                                                    !!msiData.documentExpireDate &&
                                                    msiData.documentExpireDate
                                                        .toString()
                                                        .toLowerCase() !==
                                                        'invalid date'
                                                        ? moment(
                                                              msiData.documentExpireDate
                                                          ).format(DATE_FORMAT)
                                                        : ''
                                                }
                                                style={{ width: '100%' }}
                                            />
                                        </Space>
                                    </Space>
                                )}
                                <br />
                                <Title level={3}>Контактные данные</Title>
                                {msiData.email && (
                                    <Space
                                        direction={'vertical'}
                                        style={{
                                            width: '100%',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <Space
                                            direction={'vertical'}
                                            style={{ width: '100%' }}
                                        >
                                            <Text>
                                                Номер мобильного телефона,
                                                использующийся для
                                                аутентификации
                                            </Text>
                                            <Input
                                                disabled
                                                value={msiData.phone || ''}
                                                style={{ width: '100%' }}
                                            />
                                        </Space>
                                        <Space
                                            direction={'vertical'}
                                            style={{ width: '100%' }}
                                        >
                                            <Text>
                                                Иные номера контактных телефонов
                                            </Text>
                                            <Input
                                                disabled
                                                value={msiData.otherPhones
                                                    .filter(
                                                        (x) =>
                                                            x !==
                                                                msiData.phone ||
                                                            ''
                                                    )
                                                    .join(', ')}
                                                style={{ width: '100%' }}
                                            />
                                        </Space>
                                        <Space
                                            direction={'vertical'}
                                            style={{ width: '100%' }}
                                        >
                                            <Text>Адрес электронной почты</Text>
                                            <Input
                                                disabled
                                                value={
                                                    msiData.email.length > 0
                                                        ? msiData.email[0]
                                                        : ''
                                                }
                                                style={{ width: '100%' }}
                                            />
                                        </Space>
                                    </Space>
                                )}
                            </Space>
                        ) : (
                            <Space direction={'vertical'}>
                                <Text>{localize('noVerification')}</Text>
                                <Button
                                    type={'primary'}
                                    disabled={msiDataLoading}
                                    onClick={() => navigate(Links.Wizard)}
                                >
                                    {localize('goToVerification')}
                                </Button>
                            </Space>
                        )}
                    </>
                )}
            {isLoaded &&
                accesses &&
                (accesses[USER_TYPE] === UserType.Legal ||
                    accesses[USER_TYPE] === UserType.IndividualLegal) && (
                    <>
                        {!dataEnabled && (
                            <Alert
                                type='error'
                                banner={true}
                                message='Проблема с идентификацией. Обратитесь в службу поддержки платформы.'
                            ></Alert>
                        )}
                        {dataIncomplete && (
                            <Alert
                                type='warning'
                                banner={true}
                                message='Отсутствуют обязательные данные для идентификации. Обратитесь в службу поддержки платформы.'
                            ></Alert>
                        )}
                        {msiLegalData ? (
                            <Space
                                direction={'vertical'}
                                style={{ width: '100%' }}
                            >
                                <Title level={3}>Сведения о реквизитах</Title>
                                <Space
                                    direction={
                                        isMobile ? 'vertical' : 'horizontal'
                                    }
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>
                                            Полное наименование организации
                                        </Text>
                                        <Input
                                            disabled
                                            value={msiLegalData.orgName || ''}
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                                <Space
                                    direction={
                                        isMobile ? 'vertical' : 'horizontal'
                                    }
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>
                                            Сокращенное наименование организации
                                        </Text>
                                        <Input
                                            disabled
                                            value={
                                                msiLegalData.orgShortName || ''
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                                <Space
                                    direction={'vertical'}
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Тип организации</Text>
                                        <Input
                                            disabled
                                            value={
                                                Object.entries(
                                                    idLegalTypeMapping
                                                ).filter(
                                                    (x) =>
                                                        x[0] ===
                                                            msiLegalData.type ||
                                                        ''
                                                )[0][1]
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Статус</Text>
                                        <Input
                                            disabled
                                            value={msiLegalData.status || ''}
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Учетный номер плательщика</Text>
                                        <Input
                                            disabled
                                            value={msiLegalData.unp || ''}
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                                <br />
                                <Title level={3}>Контактные данные</Title>
                                {(msiLegalData.emails || true) && (
                                    <Space
                                        direction={'vertical'}
                                        style={{
                                            width: '100%',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <Space
                                            direction={'vertical'}
                                            style={{ width: '100%' }}
                                        >
                                            <Text>
                                                Номер мобильного телефона для
                                                SMS подтверждения
                                            </Text>
                                            <Input
                                                disabled
                                                value={
                                                    msiLegalData.smsVerificationPhones ||
                                                    ''
                                                }
                                                style={{ width: '100%' }}
                                            />
                                        </Space>
                                        <Space
                                            direction={'vertical'}
                                            style={{ width: '100%' }}
                                        >
                                            <Text>
                                                Иные номера контактных телефонов
                                            </Text>
                                            <Input
                                                disabled
                                                value={(
                                                    msiLegalData.mobilePhones ||
                                                    []
                                                )
                                                    .concat(
                                                        msiLegalData.cityPhones ||
                                                            []
                                                    )
                                                    .filter(
                                                        (x) =>
                                                            x !==
                                                                msiLegalData.smsVerificationPhones ||
                                                            ''
                                                    )
                                                    .join(', ')}
                                                style={{ width: '100%' }}
                                            />
                                        </Space>
                                        <Space
                                            direction={'vertical'}
                                            style={{ width: '100%' }}
                                        >
                                            <Text>
                                                Адреса электронной почты
                                            </Text>
                                            <Input
                                                disabled
                                                value={
                                                    (msiLegalData.emails || [])
                                                        .length > 0
                                                        ? msiLegalData.emails?.join(
                                                              ', '
                                                          )
                                                        : ''
                                                }
                                                style={{ width: '100%' }}
                                            />
                                        </Space>
                                    </Space>
                                )}
                            </Space>
                        ) : (
                            <Space direction={'vertical'}>
                                <Text>{localize('noVerification')}</Text>
                                <Button
                                    type={'primary'}
                                    disabled={msiDataLoading}
                                    onClick={() => navigate(Links.Wizard)}
                                >
                                    {localize('goToVerification')}
                                </Button>
                            </Space>
                        )}
                    </>
                )}
        </div>
    )
}
