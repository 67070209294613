import { all, put, StrictEffect, takeLeading, call } from 'redux-saga/effects'
import {
    Paginated,
    SagaGenerator,
    WithCallback,
    WithLocalize,
} from '../../../@types/common'
import { ActionCreatorWithPayload, PayloadAction } from '@reduxjs/toolkit'
import { Api } from '../index'
import { ApiResponse } from 'apisauce'
import moment from 'moment/moment'
import { ReferralBalanceActions } from '../../reducers/referralBalance'
import {
    ReferralBalance,
    ReferralWithdrawalPayload,
    UserReferralInfoResult,
} from '../../reducers/referralBalance/types'
import { throwNotification } from '../../../components/Notification'
import { NoticeTypes } from '../../../@types/notifications'

export function* requestReferralBalance(
    api: Api,
    action: PayloadAction<Partial<Paginated<{}>>>
) {
    const { page, perPage } = action.payload

    yield put(ReferralBalanceActions.setReferralBalanceLoading(true))

    let result: ApiResponse<Paginated<ReferralBalance>> = yield call(
        api.getReferralBalance,
        page,
        perPage
    )

    if (result.status === 200 && result.data) {
        for (let offer of result.data.data) {
            offer.createdAt = moment(offer.createdAt)
            offer.deletedAt = offer.deletedAt
                ? moment(offer.deletedAt)
                : undefined
            offer.updatedAt = moment(offer.updatedAt)
        }
        yield put(ReferralBalanceActions.setReferralBalance(result.data))
    }

    yield put(ReferralBalanceActions.setReferralBalanceLoading(false))
}

export function* requestMinBalance(api: Api) {
    let result: ApiResponse<{ value: number }> = yield call(
        api.getReferralMinBalance
    )

    if (result.status === 200 && result.data) {
        yield put(ReferralBalanceActions.setMinBalance(result.data.value))
    }
}

export function* requestReferralInfo(api: Api) {
    yield put(ReferralBalanceActions.setReferralInfoLoading(false))

    let result: ApiResponse<UserReferralInfoResult> = yield call(
        api.getReferralInfo
    )

    if (result.status === 200 && result.data) {
        yield put(ReferralBalanceActions.setReferralInfo(result.data))
    }

    yield put(ReferralBalanceActions.setReferralInfoLoading(false))
}

export function* requestWithdrawal(
    api: Api,
    action: PayloadAction<WithLocalize<WithCallback<ReferralWithdrawalPayload>>>
) {
    const { callback, amount, localize } = action.payload

    let result: ApiResponse<UserReferralInfoResult> = yield call(
        api.withdrawReferralBalance,
        amount
    )

    if (result.status === 200 && result.data) {
        throwNotification(
            NoticeTypes.Success,
            localize('referralWithdrawalSuccess')
        )
        callback(result.data)
    }
}

export default function* groupsSagas(api: Api): SagaGenerator<StrictEffect> {
    yield all([
        yield takeLeading(
            ReferralBalanceActions.requestReferralBalance as ActionCreatorWithPayload<
                Partial<Paginated<{}>>
            >,
            requestReferralBalance,
            api
        ),
        yield takeLeading(
            ReferralBalanceActions.requestMinBalance,
            requestMinBalance,
            api
        ),
        yield takeLeading(
            ReferralBalanceActions.requestReferralInfo,
            requestReferralInfo,
            api
        ),
        yield takeLeading(
            ReferralBalanceActions.requestWithdrawal as ActionCreatorWithPayload<
                WithLocalize<WithCallback<ReferralWithdrawalPayload>>
            >,
            requestWithdrawal,
            api
        ),
    ])
}
