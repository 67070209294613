import { SelectProps } from 'antd'
import _ from 'lodash'
import { countries } from '../../locales'
import { firstLetterUpper } from '../../utils/helpers'

export const incomeSourceOptions = _.sortBy(
    [
        {
            label: 'Бизнес-деятельность организации, прибыль',
            value: 1,
        },
        {
            label: 'Заработная плата',
            value: 2,
        },
        {
            label: 'Кредиты/займы',
            value: 3,
        },
        {
            label: 'Личные сбережения',
            value: 4,
        },
        {
            label: 'Проценты от сбережений (депозит) на банковском счете',
            value: 5,
        },
        {
            label: 'иное (указать подробно):',
            value: 6,
        },
    ],
    ['value']
)

export const workExperienceOptions = _.sortBy(
    [
        {
            label: 'До 1 года',
            value: 56,
        },
        {
            label: 'От 1 года до 2 лет',
            value: 57,
        },
        {
            label: 'Свыше 2 лет',
            value: 58,
        },
    ],
    ['value']
)

export const incomeAmountOptions = _.sortBy(
    [
        {
            label: 'До 500',
            value: 41,
        },
        {
            label: '501 - 1000',
            value: 42,
        },
        {
            label: '1001 - 1500',
            value: 43,
        },
        {
            label: '1501 - 2000',
            value: 44,
        },
        {
            label: '2001 - 2500',
            value: 45,
        },
        {
            label: '2501 и выше',
            value: 46,
        },
    ],
    ['value']
)

export const countriesOptions: SelectProps['options'] = _.sortBy(
    countries.data.map((x) => ({
        value: Number.parseInt(x.code),
        label: firstLetterUpper(x.shortname),
    })),
    ['label']
)

export const employmentTypeOptions: SelectProps['options'] = _.sortBy(
    [
        {
            value: 47,
            label: 'В декретном отпуске',
        },
        {
            value: 48,
            label: 'Временно не работаю',
        },
        {
            value: 49,
            label: 'Иной источник дохода',
        },
        {
            value: 50,
            label: 'Пенсионер',
        },
        {
            value: 52,
            label: 'Предприниматель',
        },
        {
            value: 55,
            label: 'Работаю официально',
        },
        {
            value: 51,
            label: 'Работающий пенсионер',
        },
        {
            value: 53,
            label: 'Самозанятый',
        },
        {
            value: 54,
            label: 'Студент',
        },
    ],
    ['value']
)

export const activityFieldOptions: SelectProps['options'] = _.sortBy(
    [
        {
            value: 1,
            label: 'Банковское дело / финансы',
        },
        {
            value: 2,
            label: 'Вооружённые силы / правоохр. органы',
        },
        {
            value: 3,
            label: 'Государственное управление',
        },
        {
            value: 4,
            label: 'Здравоохранение / фармация',
        },
        {
            value: 5,
            label: 'IT',
        },
        {
            value: 6,
            label: 'Коммунальное хозяйство',
        },
        {
            value: 7,
            label: 'Культура',
        },
        {
            value: 8,
            label: 'Наука и образование',
        },
        {
            value: 9,
            label: 'Промышленность',
        },
        {
            value: 10,
            label: 'Развлечения',
        },
        {
            value: 11,
            label: 'Сельское хозяйство',
        },
        {
            value: 12,
            label: 'Строительство',
        },
        {
            value: 13,
            label: 'Торговля',
        },
        {
            value: 14,
            label: 'Услуги',
        },
    ],
    ['value']
)

export const educationOptions: SelectProps['options'] = _.sortBy(
    [
        {
            value: 26,
            label: 'Высшее образование',
        },
        {
            value: 25,
            label: 'Два и более высших образования',
        },
        {
            value: 24,
            label: 'МВА/ученая степень',
        },
        {
            value: 29,
            label: 'Неоконченное среднее',
        },
        {
            value: 28,
            label: 'Среднее',
        },
        {
            value: 27,
            label: 'Среднее специальное',
        },
    ],
    ['value']
)

export const maritalStatusOptions: SelectProps['options'] = _.sortBy(
    [
        {
            value: 34,
            label: 'Холост / не замужем',
        },
        {
            value: 30,
            label: 'Вдовец / вдова',
        },
        {
            value: 31,
            label: 'В разводе',
        },
        {
            value: 32,
            label: 'Гражданский брак',
        },
        {
            value: 33,
            label: 'Женат / замужем',
        },
    ],
    ['value']
)

export const housingConditionOptions: SelectProps['options'] = _.sortBy(
    [
        {
            value: 35,
            label: 'Арендуемая квартира / дом',
        },
        {
            value: 36,
            label: 'Общежитие',
        },
        {
            value: 37,
            label: 'Служебное жилье',
        },
        {
            value: 38,
            label: 'Собственный дом / квартира',
        },
        {
            value: 39,
            label: 'Совместно с родственниками',
        },
        {
            value: 40,
            label: 'Иное',
        },
    ],
    ['value']
)

export const minorChildrenOptions: SelectProps['options'] = _.sortBy(
    [
        {
            value: 1,
            label: '1 ребенок',
        },
        {
            value: 2,
            label: '2 ребенка',
        },
        {
            value: 3,
            label: '3 и более детей',
        },
        {
            value: 4,
            label: 'Нет детей',
        },
    ],
    ['value']
)
