import React, { FC, useCallback } from 'react'
import { Button } from 'antd'
import { useDispatch } from 'react-redux'
import { HomeActions } from '../../redux/reducers/home'

export const PaymentComponent: FC = () => {
    const dispatch = useDispatch()

    const callback = useCallback(() => {
        dispatch(
            HomeActions.requestBepaidAuthParams({
                callback: (params) => {
                    let constructorParams = {
                        ...params,
                        closeWidget: function (status: string) {
                            console.debug('close widget callback ' + status)
                        },
                    }
                    new BeGateway(constructorParams).createWidget()
                },
            })
        )
    }, [dispatch])

    return (
        <div>
            <Button onClick={callback}>Привязать карту</Button>
        </div>
    )
}
