import React, { FC, useEffect, useMemo } from 'react'
import { useLocalizationContext, useMobile } from '../../utils/contexts'
import classNames from 'classnames'
import styles from './EditOfferPage.module.scss'
import { PageTitle } from '../../components'
import { FileAddOutlined, FileOutlined } from '@ant-design/icons'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Links } from '../../constants/routes'
import { Button, Form, FormProps, InputNumber } from 'antd'
import { Offer, OfferType } from '../../redux/reducers/offers/types'
import { useDispatch, useSelector } from 'react-redux'
import { OffersActions, OffersSelectors } from '../../redux/reducers/offers'
import { DocumentsActions } from '../../redux/reducers/documents'

type State = Partial<Offer>

export const EditOfferPage: FC = () => {
    const dispatch = useDispatch()
    const { formatLocalize, localize } = useLocalizationContext()
    const isMobile = useMobile()
    const location = useLocation()
    const navigate = useNavigate()
    const { id } = useParams()

    const [form] = Form.useForm<State>()
    const rateValue = Form.useWatch('loanRate', form)
    const amountValue = Form.useWatch('amount', form)

    const isEdit = id !== undefined

    const isBid = useMemo(
        () =>
            !![Links.NewBidOffer, Links.EditBidOffer.replace(':id', '')].find(
                (x) => location.pathname.includes(x)
            ),
        [location.pathname]
    )

    useEffect(() => {
        dispatch(OffersActions.setSpecificOffer(undefined))
    }, [location.pathname])

    const specificOffer = useSelector(OffersSelectors.getSpecificOffer)
    const offerVars = useSelector(OffersSelectors.getOfferVars)

    useEffect(() => {
        if (id) {
            dispatch(
                OffersActions.requestSpecificOffer({
                    id,
                    callback: () => {
                        navigate(Links.Offers)
                    },
                })
            )
        }
    }, [id])

    useEffect(() => {
        if (isEdit) {
            if (
                specificOffer &&
                specificOffer.type !== (isBid ? OfferType.Bid : OfferType.Ask)
            ) {
                navigate(
                    (specificOffer.type === OfferType.Ask
                        ? Links.EditAskOffer
                        : Links.EditBidOffer
                    ).replace(':id', specificOffer.id!)
                )
            }
        }
    }, [specificOffer, isEdit])

    const onFinish: FormProps<State>['onFinish'] = (values) => {
        const loanRate = values.loanRate as any

        const commonProps = {
            type: isBid ? OfferType.Bid : OfferType.Ask,
            loanTerm: values.loanTerm!,
            penaltyRate:
                typeof values.penaltyRate! === 'string'
                    ? Number.parseFloat(values.penaltyRate!)
                    : values.penaltyRate!,
            amount: values.amount!,
            count: values.count!,
            score: values.score,
            loanRate:
                typeof loanRate === 'string'
                    ? Number.parseFloat(loanRate)
                    : loanRate,
            localize,
            callback: (value: Offer) => {
                dispatch(DocumentsActions.requestDocuments())
                navigate(Links.ViewOffer.replace(':id', value.id!))
            },
        }
        if (isEdit) {
            dispatch(
                OffersActions.updateOffer({
                    ...commonProps,
                    id,
                })
            )
        } else {
            dispatch(OffersActions.createOffer(commonProps))
        }
    }

    return (
        <div
            className={classNames(styles.pageContainer, {
                [styles.pageContainerMobile]: isMobile,
            })}
        >
            <PageTitle
                title={formatLocalize(isEdit ? 'editOffer' : 'newOffer', {
                    type: isBid ? localize('bid') : localize('ask'),
                    id: id?.substring(0, 6) || '',
                })}
                icon={isEdit ? <FileOutlined /> : <FileAddOutlined />}
            />
            {(isEdit ? specificOffer : true) && (
                <Form
                    form={form}
                    name='newOfferForm'
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    initialValues={isEdit ? specificOffer : {}}
                    onFinish={onFinish}
                    autoComplete='off'
                >
                    <Form.Item<State>
                        label={localize('amount')}
                        name='amount'
                        rules={[
                            {
                                required: true,
                                message: localize('amountRequired'),
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            max={10000}
                            addonAfter={localize('currency')}
                        />
                    </Form.Item>
                    <Form.Item<State>
                        label={localize('loanTerm')}
                        name='loanTerm'
                        rules={[
                            {
                                required: true,
                                message: localize('loanTermRequired'),
                            },
                        ]}
                    >
                        <InputNumber
                            min={1}
                            max={365}
                            addonAfter={localize('dayOne')}
                        />
                    </Form.Item>
                    <Form.Item<State>
                        label={localize('loanRate')}
                        name='loanRate'
                        rules={[
                            {
                                required: true,
                                message: localize('loanRateRequired'),
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            max={10}
                            step={0.1}
                            addonAfter={'%'}
                        />
                    </Form.Item>
                    <Form.Item<State>
                        label={localize('penaltyRate')}
                        name='penaltyRate'
                        rules={[
                            {
                                required: true,
                                message: localize('penaltyRateRequired'),
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            max={10}
                            step={0.1}
                            addonAfter={'%'}
                        />
                    </Form.Item>
                    {isBid && (
                        <Form.Item<State>
                            label={localize('scoreMin')}
                            name='score'
                            rules={[
                                {
                                    required: true,
                                    message: localize('scoreRequired'),
                                },
                            ]}
                        >
                            <InputNumber min={0} max={100} step={1} />
                        </Form.Item>
                    )}
                    <Form.Item<State>
                        label={localize('count')}
                        name='count'
                        rules={[
                            {
                                required: true,
                                message: localize('countRequired'),
                            },
                        ]}
                    >
                        <InputNumber min={1} max={10} step={1} />
                    </Form.Item>
                    {isBid && (
                        <>
                            <Form.Item<State>
                                label={'Годовая процентная ставка'}
                            >
                                <InputNumber
                                    value={
                                        rateValue !== undefined
                                            ? rateValue *
                                              (offerVars?.daysInYear || 360)
                                            : ''
                                    }
                                    disabled
                                    addonAfter={'%'}
                                />
                            </Form.Item>
                            <Form.Item<State> label={'Вознограждение сервиса'}>
                                <InputNumber
                                    value={
                                        offerVars?.orderCreationFee !==
                                            undefined &&
                                        amountValue !== undefined
                                            ? Math.round(
                                                  offerVars.orderCreationFee *
                                                      amountValue *
                                                      100
                                              ) / 100
                                            : ''
                                    }
                                    disabled
                                    addonAfter={localize('currency')}
                                />
                            </Form.Item>
                        </>
                    )}
                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            {localize(isEdit ? 'save' : 'create')}
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </div>
    )
}
