import { UserErrorModel } from "../../../openapi/identification/data-contracts"

export type MsiState = {
    msiExists?: boolean
    msiUrl?: string
    msiUrlLoading: boolean
    msiData?: UserErrorModel
    msiDataLoading: boolean
}

// TODO: Reuse from identification
export enum IdDocumentType {
    BelarusPassport = '3',
    BelarusResidentCard = '6',
    IdCardBelarus = '15',
    BiometricResidentCard = '16',
    BiometricResidentCardWithoutCitizenship = '17',
}
