import apisauce from 'apisauce'
import {
    getAuthHeader,
    headers as commonHeaders,
    requestCreate,
    RequestMethod,
} from '../helpers'
import { env } from '../../env'
import {
    AuthResponse,
    LeaderboardEntity,
    RegStatus,
    User,
    UserScore,
} from '../../redux/reducers/home/types'
import { Card } from '../../redux/reducers/cards/types'
import {
    Dictionary,
    FiltersType,
    Paginated,
    SortOrder,
} from '../../@types/common'
import { Offer, OfferType, OfferVars } from '../../redux/reducers/offers/types'
import { Order } from '../../redux/reducers/orders/types'
import { Document } from '../../redux/reducers/documents/types'
import { TabIndex } from '../../pages/WizardPage'
import { QuestionnaireData } from '../../redux/reducers/questionnaire/types'
import { UserType } from '../../openapi/identification/data-contracts'
import { UserReferralInfoResult } from '../../redux/reducers/referralBalance/types'

const create = () => {
    const coreApi = () =>
        apisauce.create({
            headers: {
                ...commonHeaders,
                Authorization: getAuthHeader(),
            },
            // TODO: It should work in all envs automatically
            baseURL: window?.location?.href?.includes('.app')
                ? 'http://loan.api.app:3300'
                : window?.location?.href?.includes('localhost')
                    // TODO: Avoid this hardcode, move it to config?
                    ? 'http://localhost:3300'
                    : env.REACT_APP_CORE_API_URL,
        })

    const request = requestCreate(coreApi)

    const logIn = (username: string, password: string) =>
        request<AuthResponse>({
            path: `/auth/login`,
            params: {
                login: username,
                password,
            },
            method: RequestMethod.POST,
        })

    const registerUser = (
        username: string,
        password: string,
        type: UserType,
        email?: string,
        number?: string,
        referral?: string
    ) =>
        request<AuthResponse>({
            path: `/auth/registration`,
            params: {
                login: username,
                password,
                type,
                email,
                number,
                referral,
            },
            method: RequestMethod.POST,
        })

    const getCurrentUser = () =>
        request<AuthResponse>({
            path: `/auth/me`,
            method: RequestMethod.GET,
        })

    const getUserAccesses = () =>
        request<Dictionary<boolean>>({
            path: `/user/accesses`,
            method: RequestMethod.GET,
        })

    const deleteCard = (id: string) =>
        request({
            path: `/card/${id}`,
            method: RequestMethod.DELETE,
        })

    const listCards = (page?: number, perPage?: number) =>
        request<Paginated<Card>>({
            path: `/card`,
            method: RequestMethod.GET,
            params: {
                page,
                perPage,
            },
        })

    const createOffer = (
        amount: number,
        loanTerm: number,
        loanRate: number,
        type: OfferType,
        count: number,
        penaltyRate: number,
        score?: number
    ) =>
        request<Offer>({
            path: `/offer`,
            method: RequestMethod.POST,
            params: {
                amount,
                loanTerm,
                loanRate,
                type,
                count,
                penaltyRate,
                score,
            },
        })

    const updateOffer = (
        id: string,
        amount: number,
        loanTerm: number,
        loanRate: number,
        type: OfferType,
        count: number,
        penaltyRate: number,
        score?: number
    ) =>
        request<Offer>({
            path: `/offer/${id}`,
            method: RequestMethod.PUT,
            params: {
                amount,
                loanTerm,
                loanRate,
                type,
                count,
                penaltyRate,
                score,
            },
        })

    const closeOffer = (id: string) =>
        request({
            path: `/offer/${id}`,
            method: RequestMethod.DELETE,
        })

    const listOffers = (
        query: string,
        filters: FiltersType,
        page?: number,
        perPage?: number,
        sortBy?: string,
        sortOrder?: SortOrder
    ) =>
        request<Paginated<Offer>>({
            path: `/offer`,
            method: RequestMethod.GET,
            params: {
                query,
                page,
                perPage,
                filters,
                sortBy,
                sortOrder,
            },
        })

    const listMyOffers = (
        query: string,
        filters: FiltersType,
        page?: number,
        perPage?: number,
        sortBy?: string,
        sortOrder?: SortOrder
    ) =>
        request<Paginated<Offer>>({
            path: `/user/offers`,
            method: RequestMethod.GET,
            params: {
                query,
                page,
                perPage,
                filters,
                sortBy,
                sortOrder,
            },
        })

    const getSpecificOffer = (id: string) =>
        request<Offer>({
            path: `/offer/${id}`,
            method: RequestMethod.GET,
        })

    const getSpecificOrder = (id: string) =>
        request<Order>({
            path: `/order/${id}`,
            method: RequestMethod.GET,
        })

    const listUserOrders = (
        query: string,
        filters: FiltersType,
        page?: number,
        perPage?: number,
        sortBy?: string,
        sortOrder?: SortOrder
    ) =>
        request<Paginated<Order>>({
            path: `/user/orders`,
            method: RequestMethod.GET,
            params: {
                query,
                page,
                perPage,
                filters,
                sortBy,
                sortOrder,
            },
        })

    const listOfferOrders = (
        id: string,
        filters: FiltersType,
        page?: number,
        perPage?: number,
        sortBy?: string,
        sortOrder?: SortOrder
    ) =>
        request<Paginated<Order>>({
            path: `/offer/${id}/orders`,
            method: RequestMethod.GET,
            params: {
                page,
                perPage,
                filters,
                sortBy,
                sortOrder,
            },
        })

    const createOrder = (offer_id: string) =>
        request<Order>({
            path: `/order`,
            method: RequestMethod.POST,
            params: {
                offer_id,
            },
        })

    const signUserAgreement = (type: TabIndex) =>
        request({
            path: `/user/sign`,
            method: RequestMethod.POST,
            params: {
                type,
            },
        })

    const isMsiDataExists = () =>
        request({
            path: `/msi/check`,
            method: RequestMethod.GET,
        })

    const getMsiURL = () =>
        request({
            path: `/msi/url`,
            method: RequestMethod.GET,
        })

    const getMsiData = () =>
        request({
            path: `/msi/data`,
            method: RequestMethod.GET,
        })

    const sendSms = (document_id: string) =>
        request({
            path: `/sms/send`,
            method: RequestMethod.POST,
            params: {
                document_id,
            },
        })

    const signWithSms = (document_id: string, code: string) =>
        request({
            path: `/sms/sign`,
            method: RequestMethod.POST,
            params: {
                document_id,
                code,
            },
        })

    const bepaidAuthParams = () =>
        request({
            path: `/bepaid/auth`,
            method: RequestMethod.GET,
        })

    const listDocuments = () =>
        request<Document[]>({
            path: `/documents`,
            method: RequestMethod.GET,
        })

    const getSpecificDocument = (id: string) =>
        request<Document>({
            path: `/documents/${id}`,
            method: RequestMethod.GET,
        })

    const cancelSpecificDocument = (id: string) =>
        request({
            path: `/documents/${id}`,
            method: RequestMethod.DELETE,
        })

    const regStatus = () =>
        request<RegStatus>({
            path: `/auth/status`,
            method: RequestMethod.GET,
        })

    const requestScore = () =>
        request<UserScore>({
            path: `/user/score`,
            method: RequestMethod.GET,
        })

    const offerVars = () =>
        request<OfferVars>({
            path: `/offer-vars`,
            method: RequestMethod.GET,
        })

    const getQuestionnaireData = () =>
        request<QuestionnaireData>({
            path: `/questionnaire`,
            method: RequestMethod.GET,
        })

    const postQuestionnaireData = (data: QuestionnaireData) =>
        request<QuestionnaireData>({
            path: `/questionnaire`,
            method: RequestMethod.POST,
            params: data,
        })

    const questionnaireExists = () =>
        request({
            path: `/questionnaire/exists`,
            method: RequestMethod.GET,
        })

    const payForOrder = (orderId: string) =>
        request({
            path: `/order/${orderId}/pay`,
            method: RequestMethod.GET,
        })

    const setDefaultCard = (cardId: string) =>
        request<User>({
            path: `/user/card`,
            method: RequestMethod.POST,
            params: {
                defaultCard: cardId,
            },
        })

    const getLeaderboard = () =>
        request<LeaderboardEntity[]>({
            path: `/leaderboard`,
            method: RequestMethod.GET,
        })

    const getReferralInfo = () =>
        request<UserReferralInfoResult>({
            path: `/referral-balance/info`,
            method: RequestMethod.GET,
        })

    const getReferralBalance = (page?: number, perPage?: number) =>
        request<UserReferralInfoResult>({
            path: `/referral-balance`,
            method: RequestMethod.GET,
            params: {
                page,
                perPage,
            },
        })

    const getReferralMinBalance = () =>
        request<{ value: number }>({
            path: `/referral-balance/min-balance`,
            method: RequestMethod.GET,
        })

    const withdrawReferralBalance = (amount: number) =>
        request<{ value: number }>({
            path: `/referral-balance/withdraw`,
            method: RequestMethod.POST,
            params: {
                amount,
            },
        })

    return {
        logIn,
        getCurrentUser,
        registerUser,
        listCards,
        deleteCard,
        listOffers,
        createOffer,
        updateOffer,
        closeOffer,
        getSpecificOffer,
        getSpecificOrder,
        listMyOffers,
        listUserOrders,
        listOfferOrders,
        createOrder,
        getUserAccesses,
        signUserAgreement,
        isMsiDataExists,
        getMsiURL,
        getMsiData,
        sendSms,
        bepaidAuthParams,
        listDocuments,
        getSpecificDocument,
        signWithSms,
        regStatus,
        offerVars,
        getQuestionnaireData,
        postQuestionnaireData,
        questionnaireExists,
        payForOrder,
        setDefaultCard,
        cancelSpecificDocument,
        requestScore,
        getLeaderboard,
        getReferralInfo,
        getReferralBalance,
        getReferralMinBalance,
        withdrawReferralBalance,
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    create,
}
