import { all, put, StrictEffect, takeLeading, call } from 'redux-saga/effects'
import { SagaGenerator } from '../../../@types/common'
import { Api } from '../index'
import { ApiResponse } from 'apisauce'
import { MsiActions } from '../../reducers/msi'
import { UserErrorModel } from '../../../openapi/identification/data-contracts'

export function* requestMsiExists(api: Api) {
    let result: ApiResponse<{ exists: boolean }> = yield call(
        api.isMsiDataExists
    )

    if (result.status === 200 && result.data) {
        yield put(MsiActions.setMsiExists(true))
    } else {
        yield put(MsiActions.setMsiExists(false))
    }
}

export function* requestMsiUrl(api: Api) {
    yield put(MsiActions.setMsiUrlLoading(true))

    let result: ApiResponse<{ url: string }> = yield call(api.getMsiURL)

    if (result.status === 200 && result.data) {
        yield put(MsiActions.setMsiUrl(result.data.url))
    }

    yield put(MsiActions.setMsiUrlLoading(false))
}

export function* requestMsiData(api: Api) {
    yield put(MsiActions.setMsiDataLoading(true))

    let result: ApiResponse<any> = yield call(api.getMsiData)

    if (result.status === 200 && result.data && !result.data.statusCode) {
        let msiData: UserErrorModel = result.data
        yield put(MsiActions.setMsiData(msiData))
    }

    yield put(MsiActions.setMsiDataLoading(false))
}

export default function* groupsSagas(api: Api): SagaGenerator<StrictEffect> {
    yield all([
        yield takeLeading(MsiActions.requestMsiExists, requestMsiExists, api),
        yield takeLeading(MsiActions.requestMsiURL, requestMsiUrl, api),
        yield takeLeading(MsiActions.requestMsiData, requestMsiData, api),
    ])
}
