import { useMemo } from 'react'
import { BEPAID_SIGNED, MSI_SIGNED } from '../../@types/accesses'
import { Dictionary } from '../../@types/common'
import { UserType } from '../../openapi/identification/data-contracts'

export function useShowingWizzard(accesses?: Dictionary<boolean | UserType>) {
    return useMemo(
        () => accesses && !accesses[BEPAID_SIGNED] && !accesses[MSI_SIGNED],
        [accesses]
    )
}
