/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * User type
 * * `0` - individual user
 * * `1` - legal user
 * * `2` - legal individual user
 * @default 0
 */
export enum UserType {
    Individual = 0,
    Legal = 1,
    IndividualLegal = 2,
}

export interface UserModel {
    /** User identifier */
    user: string
    /**
     * User type
     * * `0` - individual user
     * * `1` - legal user
     * * `2` - legal individual user
     */
    type: UserType
}

/** Contains information about the user */
export interface UserCompositeModel {
    enabled: boolean
    individual?: UserIndividualModel
    legal?: UserLegalModel
}

export interface UserLegalModel {
    /** Payer's account number */
    unp: string
    /** Organization name */
    orgName: string
    /** Short organization name */
    orgShortName?: string | null
    /** Type of organization */
    type: string
    /** Status of organization */
    status?: string | null
    /** Phone for sms verification */
    smsVerificationPhones?: string | null
    /** Array of user's mobile phones */
    mobilePhones?: string[]
    /** Array of user's city phones */
    cityPhones?: string[]
    /** Array of user's emails */
    emails?: string[]
}

export interface UserIndividualModel {
    /** First name of user */
    firstName: string
    /** Last name of user */
    lastName: string
    /** Second name of user */
    secondName?: string
    /**
     * Array of user's emails
     * @default []
     */
    email: string[]
    /** Main phone of user */
    phone: string
    /**
     * Array of other user phones
     * @default []
     */
    otherPhones: string[]
    /** Document identifier */
    documentId: string
    /** Type of the document */
    documentType: string
    /** Document series number */
    documentNumber: string
    /** @format date-time */
    documentIssueDate: string
    /** @format date-time */
    documentExpireDate: string
    /** Issuing authority of the identity document */
    documentAuthority: string
    /** Whether user is resident */
    resident: boolean
}

export interface UserExistsModel {
    /** Whether the user exists */
    exists: boolean
}

export interface UrlModel {
    /**
     * The url for OAuth redirect
     * @format uri
     */
    url: string
}

export interface ErrorModel {
    /** @example 400 */
    statusCode: number
    message: Record<string, string[]> | string
}

/** User with validation errors */
export type UserErrorModel = UserCompositeModel & ErrorModel
