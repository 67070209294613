import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useLocalizationContext, useMobile } from '../../utils/contexts'
import classNames from 'classnames'
import styles from './OffersPage.module.scss'
import { CustomPagination, PageTitle, ResponsiveTable } from '../../components'
import { OrderedListOutlined, SnippetsOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { OffersActions, OffersSelectors } from '../../redux/reducers/offers'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Input, Space, TableProps, Tag } from 'antd'
import { User } from '../../redux/reducers/home/types'
import { Links } from '../../constants/routes'
import { Moment } from 'moment'
import { DATE_TIME_FORMAT } from '../../constants'
import { useDebounce } from '../../utils/hooks'
import { Offer, OfferState, OfferType } from '../../redux/reducers/offers/types'
import { offerStateMappings } from '../ViewOfferPage'
import { SorterResult } from 'antd/es/table/interface'
import { FiltersType, SortOrder, WithSortAndFilters } from '../../@types/common'

export const OffersPage: FC = () => {
    const dispatch = useDispatch()
    const { localize, pluralize } = useLocalizationContext()
    const isMobile = useMobile()
    const location = useLocation()
    const navigate = useNavigate()
    const [query, setQuery] = useState('')
    const ref = useRef<HTMLDivElement>(null)

    const offers = useSelector(OffersSelectors.getOffers)

    const isMyPage = useMemo(
        () => location.pathname.startsWith(Links.MyOffers),
        [location.pathname]
    )

    const [sortAndFilters, setSortAndFilters] = useState<
        WithSortAndFilters<{}>
    >({
        filters: {},
    })

    const requestOffers = (
        isMyPage: boolean,
        query: string,
        page?: number,
        perPage?: number
    ) => {
        if (isMyPage) {
            dispatch(
                OffersActions.requestMyOffers({
                    page,
                    perPage,
                    query,
                    ...sortAndFilters,
                })
            )
        } else {
            dispatch(
                OffersActions.requestOffers({
                    page,
                    perPage,
                    query,
                    ...sortAndFilters,
                })
            )
        }
    }

    useEffect(() => {
        setQuery('')
    }, [location.pathname])

    const debouncedRequest = useDebounce((query: string) => {
        requestOffers(isMyPage, query)
    }, 500)

    useEffect(() => {
        debouncedRequest(query)
    }, [isMyPage, sortAndFilters])

    const columns = useMemo(
        () =>
            [
                {
                    title: localize('amount'),
                    dataIndex: 'amount',
                    key: 'amount',
                    render: (value: number) =>
                        `${value} ${localize('currency')}`,
                },
                {
                    title: localize('loanRate'),
                    dataIndex: 'loanRate',
                    key: 'loanRate',
                    render: (value: number) => `${value}%`,
                },
                {
                    title: localize('loanTerm'),
                    dataIndex: 'loanTerm',
                    key: 'loanTerm',
                    render: (value: number) =>
                        `${value} ${pluralize(value, 'dayOne', 'dayTwo', 'dayFive')}`,
                },
                {
                    title: localize('type'),
                    dataIndex: 'type',
                    key: 'type',
                    render: (type: OfferType) => (
                        <Tag color={type === OfferType.Ask ? 'blue' : 'red'}>
                            {(type === OfferType.Ask
                                ? localize('ask')
                                : localize('bid')
                            ).toUpperCase()}
                        </Tag>
                    ),
                },
                ...(isMyPage
                    ? [
                          {
                              title: localize('state'),
                              key: 'state',
                              dataIndex: 'state',
                              render: (state: OfferState) => {
                                  return (
                                      <Tag
                                          color={
                                              offerStateMappings[state].color
                                          }
                                      >
                                          {localize(
                                              offerStateMappings[state].text
                                          )}
                                      </Tag>
                                  )
                              },
                          },
                      ]
                    : [
                          {
                              title: localize('creator'),
                              dataIndex: 'owner',
                              key: 'owner',
                              render: (user: User) => user.login,
                          },
                      ]),
                {
                    title: localize('score'),
                    dataIndex: 'score',
                    key: 'score',
                    render: (value: number) => value,
                },
                {
                    title: localize('date'),
                    dataIndex: 'updatedAt',
                    key: 'updatedAt',
                    render: (value: Moment) => value.format(DATE_TIME_FORMAT),
                },
                {
                    title: localize('actions'),
                    key: 'action',
                    width: '100px',
                    dataIndex: 'action',
                    render: (_: any, record: Offer) => (
                        <Space className={styles.actions} size='middle'>
                            <Button
                                onClick={() =>
                                    navigate(
                                        Links.ViewOffer.replace(
                                            ':id',
                                            record.id!
                                        )
                                    )
                                }
                            >
                                {localize('details')}
                            </Button>
                        </Space>
                    ),
                },
            ].map((x) =>
                x.key !== 'action'
                    ? {
                          ...x,
                          sortDirections: ['descend', 'ascend'],
                          sorter: true,
                      }
                    : x
            ) as TableProps<Offer>['columns'],
        [localize, isMyPage]
    )

    const onChangeHandler: TableProps<Offer>['onChange'] = (
        _,
        filter,
        sorter,
        __
    ) => {
        const sorterCast = sorter as SorterResult<Offer>
        setSortAndFilters({
            sortBy: sorterCast.columnKey as string,
            sortOrder: sorterCast.order as SortOrder,
            filters: filter as FiltersType,
        })
    }

    const updateFilterValue = (
        key: keyof Offer,
        prop: 'min' | 'max',
        filterValue: string
    ) => {
        setSortAndFilters((value) => ({
            ...value,
            filters: {
                ...value.filters,
                [key]: {
                    ...value.filters[key],
                    [prop]: filterValue,
                },
            },
        }))
    }

    return (
        <div
            className={classNames(styles.pageContainer, {
                [styles.pageContainerMobile]: isMobile,
            })}
            ref={ref}
        >
            {isMyPage ? (
                <PageTitle
                    title={localize('myOffers')}
                    icon={<SnippetsOutlined />}
                />
            ) : (
                <PageTitle
                    title={localize('offers')}
                    icon={<OrderedListOutlined />}
                />
            )}
            <Input
                value={query}
                onChange={(e) => {
                    setQuery(e.target.value)
                    debouncedRequest(e.target.value)
                }}
                placeholder={localize('queryPlaceholder')}
            />
            <div className={styles.filters}>
                <Space.Compact>
                    <Input
                        addonBefore={localize('amountFilter')}
                        placeholder={localize('from')}
                        type={'number'}
                        onChange={(e) =>
                            updateFilterValue('amount', 'min', e.target.value)
                        }
                        style={{ width: 200 }}
                    />
                    <Input
                        placeholder={localize('to')}
                        type={'number'}
                        onChange={(e) =>
                            updateFilterValue('amount', 'max', e.target.value)
                        }
                        style={{ width: 100 }}
                    />
                </Space.Compact>
                <Space.Compact>
                    <Input
                        addonBefore={localize('termFilter')}
                        placeholder={localize('from')}
                        type={'number'}
                        onChange={(e) =>
                            updateFilterValue('loanTerm', 'min', e.target.value)
                        }
                        style={{ width: 240 }}
                    />
                    <Input
                        placeholder={localize('to')}
                        type={'number'}
                        onChange={(e) =>
                            updateFilterValue('loanTerm', 'max', e.target.value)
                        }
                        style={{ width: 100 }}
                    />
                </Space.Compact>
                <Space.Compact>
                    <Input
                        addonBefore={localize('rateFilter')}
                        placeholder={localize('from')}
                        type={'number'}
                        step={'0.1'}
                        onChange={(e) =>
                            updateFilterValue('loanRate', 'min', e.target.value)
                        }
                        style={{ width: 260 }}
                    />
                    <Input
                        placeholder={localize('to')}
                        type={'number'}
                        step={'0.1'}
                        onChange={(e) =>
                            updateFilterValue('loanRate', 'max', e.target.value)
                        }
                        style={{ width: 100 }}
                    />
                </Space.Compact>
                <Space.Compact>
                    <Input
                        addonBefore={localize('scoreFilter')}
                        type={'number'}
                        onChange={(e) =>
                            updateFilterValue('score', 'min', e.target.value)
                        }
                        placeholder={localize('from')}
                        style={{ width: 240 }}
                    />
                    <Input
                        placeholder={localize('to')}
                        type={'number'}
                        onChange={(e) =>
                            updateFilterValue('score', 'max', e.target.value)
                        }
                        style={{ width: 100 }}
                    />
                </Space.Compact>
            </div>
            <ResponsiveTable
                rowKey={'id'}
                columns={columns}
                dataSource={offers?.data || []}
                onChange={onChangeHandler}
                pagination={false}
            />
            {offers && (
                <CustomPagination
                    parentRef={ref}
                    showSizeChanger
                    onChange={(page, perPage) =>
                        requestOffers(isMyPage, query, page, perPage)
                    }
                    onShowSizeChange={(page, perPage) =>
                        requestOffers(isMyPage, query, page, perPage)
                    }
                    defaultCurrent={offers.page}
                    total={offers.total}
                />
            )}
        </div>
    )
}
