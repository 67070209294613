import { FC, useEffect, useRef, useState } from 'react'
import { useLocalizationContext, useMobile } from '../../utils/contexts'
import classNames from 'classnames'
import styles from './NotificationsPage.module.scss'
import { CustomPagination, PageTitle, ResponsiveTable } from '../../components'
import { NotificationOutlined } from '@ant-design/icons'
import { Input, TableProps } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useOrdersColumns } from './OrdersColumns'
import { useDebounce } from '../../utils/hooks'
import { OrdersActions, OrdersSelectors } from '../../redux/reducers/orders'
import {
    DocumentsActions,
    DocumentsSelectors,
} from '../../redux/reducers/documents'
import { FiltersType, SortOrder, WithSortAndFilters } from '../../@types/common'
import { SorterResult } from 'antd/es/table/interface'
import { Order } from '../../redux/reducers/orders/types'

export const OrdersPage: FC = () => {
    const dispatch = useDispatch()
    const { localize } = useLocalizationContext()
    const ref = useRef<HTMLDivElement>(null)
    const isMobile = useMobile()
    const location = useLocation()
    const [query, setQuery] = useState('')
    const [page, setPage] = useState<number>()
    const [perPage, setPerPage] = useState<number>()
    const orders = useSelector(OrdersSelectors.getOrders)
    const documents = useSelector(DocumentsSelectors.getDocuments)

    const [sortAndFilters, setSortAndFilters] = useState<
        WithSortAndFilters<{}>
    >({
        filters: {},
    })

    useEffect(() => {
        dispatch(
            OrdersActions.requestOrders({
                query,
                page,
                perPage,
                ...sortAndFilters,
            })
        )
    }, [location.pathname, documents, page, perPage, sortAndFilters])

    const onChangeHandler: TableProps<Order>['onChange'] = (
        _,
        filter,
        sorter,
        __
    ) => {
        const sorterCast = sorter as SorterResult<Order>
        setSortAndFilters({
            sortBy: sorterCast.columnKey as string,
            sortOrder: sorterCast.order as SortOrder,
            filters: filter as FiltersType,
        })
    }

    const requestOrders = () => {
        dispatch(
            OrdersActions.requestOrders({
                query,
                page,
                perPage,
                ...sortAndFilters,
            })
        )
        dispatch(DocumentsActions.requestDocuments())
    }

    const debouncedRequest = useDebounce((query: string) => {
        dispatch(
            OrdersActions.requestOrders({
                query,
                page,
                perPage,
                ...sortAndFilters,
            })
        )
    }, 500)

    const columns = useOrdersColumns(true, true, requestOrders)

    return (
        <div
            className={classNames(styles.pageContainer, {
                [styles.pageContainerMobile]: isMobile,
            })}
            ref={ref}
        >
            <PageTitle
                title={localize('orders')}
                icon={<NotificationOutlined />}
            />
            <Input
                value={query}
                onChange={(e) => {
                    setQuery(e.target.value)
                    debouncedRequest(e.target.value)
                }}
                placeholder={localize('queryPlaceholder')}
            />
            <ResponsiveTable
                rowKey={'id'}
                columns={columns}
                dataSource={orders?.data || []}
                onChange={onChangeHandler}
                pagination={false}
            />
            {orders && (
                <CustomPagination
                    parentRef={ref}
                    showSizeChanger
                    onChange={(page) => setPage(page)}
                    onShowSizeChange={(page, perPage) => setPerPage(perPage)}
                    defaultCurrent={orders.page}
                    total={orders.total}
                />
            )}
        </div>
    )
}
