import { FC } from 'react'
import { useLocalizationContext } from '../../utils/contexts'
import styles from './LoginPage.module.scss'
import { Button, Input, Form, FormProps, Typography } from 'antd'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { Links } from '../../constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { HomeActions, HomeSelectors } from '../../redux/reducers/home'
import { LoginRequestPayload } from '../../redux/reducers/home/types'
import { UserType } from '../../openapi/identification/data-contracts'

type State = Partial<LoginRequestPayload>

export const LoginPage: FC = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { localize } = useLocalizationContext()
    const regStatus = useSelector(HomeSelectors.getRegStatus)

    const onFinish: FormProps<State>['onFinish'] = (values) => {
        dispatch(
            HomeActions.requestSignIn({
                login: values.login!,
                password: values.password!,
                localize,
            })
        )
    }

    return (
        <div className={styles.form}>
            <Form
                name='loginForm'
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{}}
                onFinish={onFinish}
                autoComplete='off'
            >
                <Form.Item<State>
                    label={localize('username')}
                    name='login'
                    rules={[
                        {
                            required: true,
                            message: localize('usernameValidation'),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<State>
                    label={localize('password')}
                    name='password'
                    rules={[
                        {
                            required: true,
                            message: localize('passwordValidation'),
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item>
                    <Button type='primary' htmlType='submit'>
                        {localize('loginButton')}
                    </Button>
                </Form.Item>
            </Form>
            {regStatus?.regEnabled && (
                <>
                    <div className={styles.registrationBlock}>
                        <Typography.Text>
                            {localize('dontHaveAnAccount')}
                        </Typography.Text>
                        <Typography.Link
                            onClick={() => navigate({
                                pathname: Links.Registration,
                                search: createSearchParams({
                                    type: UserType.Individual.toString()
                                }).toString()
                            })}
                        >
                            {localize('goToRegistration')}
                        </Typography.Link>
                    </div>
                    <div className={styles.registrationBlock}>
                        <Typography.Link
                            onClick={() => navigate({
                                pathname: Links.Registration,
                                search: createSearchParams({
                                    type: UserType.Legal.toString()
                                }).toString()
                            })}
                        >
                            {localize('goToRegistration') + " ЮЛ"}
                        </Typography.Link>
                    </div>
                    <div className={styles.registrationBlock}>
                        <Typography.Link
                            onClick={() => navigate({
                                pathname: Links.Registration,
                                search: createSearchParams({
                                    type: UserType.IndividualLegal.toString()
                                }).toString()
                            })}
                        >
                            {localize('goToRegistration') + " ИП"}
                        </Typography.Link>
                    </div>
                </>
            )}
        </div>
    )
}
