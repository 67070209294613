import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import homeReducer from './reducers/home'
import cardsReducer from './reducers/cards'
import offersReducer from './reducers/offers'
import ordersReducer from './reducers/orders'
import msiReducer from './reducers/msi'
import documentsReducer from './reducers/documents'
import questionnaireReducer from './reducers/questionnaire'
import referralBalanceReducer from './reducers/referralBalance'

import rootSaga from './sagas'

const reducers = combineReducers({
    homeReducer,
    cardsReducer,
    offersReducer,
    ordersReducer,
    msiReducer,
    documentsReducer,
    questionnaireReducer,
    referralBalanceReducer,
})

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            thunk: false,
        }).concat(sagaMiddleware),
})

sagaMiddleware.run(rootSaga)

export default store
