import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MsiState } from './types'
import { UserErrorModel } from '../../../openapi/identification/data-contracts'

export const initialState: MsiState = {
    msiExists: undefined,
    msiUrl: undefined,
    msiUrlLoading: false,
    msiData: undefined,
    msiDataLoading: false,
}

const msiSlice = createSlice({
    name: 'msi',
    initialState,
    reducers: {
        requestMsiURL: () => { },
        requestMsiExists: () => { },
        requestMsiData: () => { },
        setMsiExists: (state, action: PayloadAction<boolean>) => {
            state.msiExists = action.payload
        },
        setMsiUrl: (state, action: PayloadAction<string>) => {
            state.msiUrl = action.payload
        },
        setMsiUrlLoading: (state, action: PayloadAction<boolean>) => {
            state.msiUrlLoading = action.payload
        },
        setMsiData: (state, action: PayloadAction<UserErrorModel>) => {
            state.msiData = action.payload as UserErrorModel
        },
        setMsiDataLoading: (state, action: PayloadAction<boolean>) => {
            state.msiDataLoading = action.payload
        },
    },
})

export const MsiActions = msiSlice.actions

interface MsiReducerState {
    msiReducer: MsiState
}

export const MsiSelectors = {
    getMsiExists: (state: MsiReducerState) => state.msiReducer.msiExists,
    getMsiUrl: (state: MsiReducerState) => state.msiReducer.msiUrl,
    getMsiUrlLoading: (state: MsiReducerState) =>
        state.msiReducer.msiUrlLoading,
    getMsiData: (state: MsiReducerState) => state.msiReducer.msiData,
    getMsiDataLoading: (state: MsiReducerState) =>
        state.msiReducer.msiDataLoading,
}

export default msiSlice.reducer
